import React, { useEffect, useState, useRef } from 'react';
import { Alert, Form, } from 'react-bootstrap';
import {
  ScaleButton,
  ScaleCheckbox,
  ScaleTextField,
  ScaleDropdownSelect,
  ScaleDropdownSelectItem,
  ScaleModal
} from '@telekom/scale-components-react';
import useForm from '../../../../../hooks/useForm';
import useAddProbeDevice from '../../../../../hooks/services/probe/useAddProbeDevice';

interface IModalProps {
  onHide: () => void;
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
  getProbes: any;
  probeName: any;
  probeIP: string;
  probes: any;
}

type FormErrors = {
  name?: string;
  serial?: string,
  type?: string,
  adboe?: boolean,
  version?: string,
  status?: string,
  exclusive?: string,
  rooted?: string,
  IP?: string,
};

const AddDeviceModal = ({
  onHide, setErrMessage, setMessage, getProbes, probeName, probeIP, probes
}: IModalProps) => {
  const [showError, setShowError] = useState(true);
  const [opened, setOpened] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const [addProbeDevice, { error }] = useAddProbeDevice({
    onSuccess: () => {
      setMessage('New device has been added!');
      getProbes();
    },
    onError: () => setErrMessage('Failed to create device'),
  });

  const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;

  function nextAvailableIp(): string {
    // Extract serial values
    const serials = probes.flatMap((probe:any) => probe.devices.map((device:any) => device.serial));

    const ipRegex = /^172\.16\.1\.(\d{1,3})(:5555)?$/;

    // Check if any of the IP addresses in the range are in the serials
    const usedIps = new Set<number>();
    serials.forEach((serial:any) => {
      const match = serial.match(ipRegex);
      if (match) {
        usedIps.add(parseInt(match[1], 10)); // Add the last octet of the IP address
      }
    });

    // Find the next available IP address
    for (let i = 2; i <= 255; i += 1) {
      if (!usedIps.has(i)) {
        return `172.16.1.${i}`;
      }
    }

    return 'No available IP address in the range 172.16.1.2 to 172.16.1.255';
  }

  function ensurePortSuffix(serial: string): string {
    const portSuffix = ':5555';
    if (serial.endsWith(portSuffix)) {
      return serial;
    }
    return serial + portSuffix;
  }

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues,
  } = useForm({
    initialValues: {
      name: '',
      serial: '',
      type: 'Android',
      version: '',
      adboe: true,
      status: 'online',
      exclusive: 'true',
      rooted: 'true',
      IP: '',
    },
    onSubmit: () => {
      const device: any = {
        name: formValues.name,
        type: formValues.type,
        adboe: formValues.adboe,
        version: formValues.version ? formValues.version : '',
        status: formValues.status,
        exclusive: formValues.exclusive,
        rooted: formValues.rooted,
        multiplexers: [],
      };
      if (formValues?.type === 'IP_Phone') {
        device.IP = formValues.IP;
        device.serial = formValues.IP;
      }
      if (formValues?.type === 'Analogue_Modem') {
        device.IP = probeIP;
        device.serial = probeIP;
      }
      if (formValues?.type === 'Android' && !formValues.serial && formValues.adboe) {
        device.serial = ensurePortSuffix(nextAvailableIp());
      }
      if (formValues?.type === 'Android' && formValues.serial && formValues.adboe) {
        device.serial = ensurePortSuffix(formValues.serial);
      }
      if (formValues?.type === 'Android' && !formValues.serial && !formValues.adboe) {
        device.serial = nextAvailableIp();
      }
      if (formValues?.type === 'Android' && formValues.serial && !formValues.adboe) {
        device.serial = formValues.serial;
      }
      if (formValues?.type === 'iOS' && formValues.serial) {
        device.serial = formValues.serial;
      }
      if (formValues?.type === 'iOS' && !formValues.serial) {
        device.serial = nextAvailableIp();
      }
      addProbeDevice({
        probeName,
        device,
      });
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if (!values?.name || values?.name === 0) {
        errors.name = 'Please fill in device name.';
      }
      if (values?.type === 'IP_Phone' && (!values?.IP || values?.IP === '' || !ipv4Regex.test(values?.IP))) {
        errors.IP = 'Please fill in IP.';
      }
      return errors;
    },
  });

  // show error if error changes
  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  useEffect(() => {
    setOpened(true);
  }, []);

  return (
    <ScaleModal
      heading="Add device"
      opened={opened}
      size="small"
      onScale-close={onHide}
      onScale-before-close={() => setOpened(false)}
    >
      <div style={{ minHeight: '15em' }}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          {error?.message && showError && (
          <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
            {error?.message}
          </Alert>
          )}
          <div style={{ display: 'flex', margin: '10px 0' }}>
            <ScaleDropdownSelect
              onScale-change={handleChange('type')}
              invalid={submitted && Boolean(formErrors?.type)}
              value={formValues?.type || 'Android'}
              label="Type *"
              style={{ margin: '0 5px 0 3px', flex: '1' }}
            >
              <ScaleDropdownSelectItem key="Android" value="Android">Android</ScaleDropdownSelectItem>
              <ScaleDropdownSelectItem key="iOS" value="iOS">iOS</ScaleDropdownSelectItem>
              <ScaleDropdownSelectItem key="IP_Phone" value="IP_Phone">IP-Phone</ScaleDropdownSelectItem>
              <ScaleDropdownSelectItem key="Analogue_Modem" value="Analogue_Modem">Analog Modem</ScaleDropdownSelectItem>
            </ScaleDropdownSelect>
            <ScaleTextField
              id="name"
              label="Model *"
              invalid={submitted && Boolean(formErrors?.name)}
              helperText={submitted ? formErrors?.name : ''}
              name="name"
              onChange={handleChange('name')}
              onScale-change={handleChange('name')}
              required
              type="text"
              value={formValues?.name}
              style={{ margin: '0 3px', flex: '1' }}
            />
          </div>
          <div style={{ display: 'flex', margin: '10px 0' }}>
            {((formValues?.type === 'Android' || formValues?.type === 'iOS')) && (
            <>
              <ScaleTextField
                id="serial"
                label="Serial"
                invalid={submitted && Boolean(formErrors?.serial)}
                helperText={((formValues?.type === 'Android' && formValues?.adboe) || !formValues?.type) ? ':5555 will be added by default' : ''}
                name="serial"
                onChange={handleChange('serial')}
                onScale-change={handleChange('serial')}
                required
                type="text"
                value={formValues?.serial}
                style={{ margin: '0 3px', flex: '1' }}
              />
              <ScaleTextField
                id="version"
                label="OS Version"
                invalid={submitted && Boolean(formErrors?.version)}
                helperText={submitted ? formErrors?.version : ''}
                name="version"
                onChange={handleChange('version')}
                onScale-change={handleChange('version')}
                required
                type="text"
                value={formValues?.version}
                style={{ margin: '0 3px', flex: '1' }}
              />
            </>
            )}
            {(formValues?.type === 'IP_Phone') && (
            <ScaleTextField
              id="IP"
              label="Device IP *"
              invalid={submitted && Boolean(formErrors?.IP)}
              helperText={submitted ? formErrors?.IP : ''}
              name="IP"
              onChange={handleChange('IP')}
              onScale-change={handleChange('IP')}
              required
              type="text"
              value={formValues?.IP}
              style={{ margin: '0 3px', flex: '0.5' }}
            />
            )}
          </div>
          {(formValues?.type === 'Android' || !formValues?.type) && (
          <div style={{ display: 'flex', margin: '10px 0' }}>
            <ScaleCheckbox
              id="adboe"
              label="ADBoE connected"
              onChange={handleChange('adboe')}
              onClick={handleChange('adboe')}
              value={formValues?.adboe}
              checked={formValues?.adboe}
              style={{ margin: '8px 3px', flex: '1' }}
            />
          </div>
          )}
        </Form>
      </div>
      <ScaleButton slot="action" variant="secondary" onClick={onHide}>Cancel</ScaleButton>
      <ScaleButton
        slot="action"
        onClick={() => {
          formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
          onHide();
        }}
        disabled={
            (!formValues?.name || formValues?.name === 0)
            || (formValues?.type === 'IP_Phone' && (!formValues?.IP || formValues?.IP === '' || !ipv4Regex.test(formValues?.IP)))
                }
      >
        Create
      </ScaleButton>
    </ScaleModal>
  );
};

export default AddDeviceModal;
