import _ from 'lodash';
import { useMutation, useQueryCache } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';
import { Subscriber, UpdateSubscriberSim } from '../interfaces';

type EditSubscriberRequestData = {
  id: number
  sim: Subscriber | UpdateSubscriberSim
};

type EditSubscriberMutationConfig = MutationConfig<any, any, EditSubscriberRequestData, unknown>;

export default function useEditSubscriber(options: EditSubscriberMutationConfig) {
  const axios = useAxiosWithToken();

  const queryCache = useQueryCache();

  return useMutation(({ id, sim }: EditSubscriberRequestData) => axios.put(
    `/sim-manager/api/subscribers/${id}`,
    sim,
  ).then(({ data }) => data), {
    ...options,
    onSuccess: (data, context) => {
      queryCache.invalidateQueries('subscriber');
      queryCache.invalidateQueries('subscribers');

      if (_.isFunction(options?.onSuccess)) {
        options?.onSuccess(data, context);
      }
    }
  });
}
