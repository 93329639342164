import _ from 'lodash';
import React, { useState } from 'react';

import { Button, Modal } from 'react-bootstrap';

export const ConfirmContext = React.createContext<any>({
  show: false,
});

const ConfirmModal = ({
  body, handleClose, onCancel, onOk, show, title, cancelPhrase, okPhrase
}: any) => (
  <Modal show={show} onHide={handleClose} centered style={{ borderRadius: '0.75rem' }}>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{body}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onCancel}>{cancelPhrase || 'Cancel'}</Button>
      <Button variant="primary" onClick={onOk}>{okPhrase || 'OK'}</Button>
    </Modal.Footer>
  </Modal>
);
const ConfirmProvider = ({ children }: any) => {
  const [confirmModal, setConfirmModal] = useState<any>({
    body: 'Confirm?',
    show: false,
    title: 'Confirm',
  });

  const handleClose = () => {
    setConfirmModal({
      ...confirmModal,
      show: false,
    });
  };

  const handleCancel = () => {
    setConfirmModal({
      ...confirmModal,
      show: false,
    });

    if (_.isFunction(confirmModal?.onCancel)) {
      confirmModal?.onCancel();
    }
  };

  const handleOk = () => {
    setConfirmModal({
      ...confirmModal,
      show: false,
    });

    if (_.isFunction(confirmModal?.onOk)) {
      confirmModal?.onOk();
    }
  };

  return (
    <ConfirmContext.Provider value={{ confirmModal, setConfirmModal }}>
      {children}
      {confirmModal?.show && (
        <ConfirmModal
          body={confirmModal?.body}
          handleClose={handleClose}
          show={confirmModal?.show}
          title={confirmModal?.title}
          onCancel={handleCancel}
          onOk={handleOk}
          cancelPhrase={confirmModal?.cancelPhrase}
          okPhrase={confirmModal?.okPhrase}
        />
      )}
    </ConfirmContext.Provider>
  );
};

export default ConfirmProvider;
