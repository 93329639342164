import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type RenameLocationRequestData = {
  'country_iso': string;
  'country_name': string;
};

export default function useAddProbeLocation(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ country_iso, country_name }: RenameLocationRequestData) => axios.patch(
    `/cms/api/locations/${country_iso}`,
    { country_name },
  ).then(({ data }) => data), options);
}
