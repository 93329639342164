import { useMutation, useQueryCache } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type DeleteLocationRequestData = {
  country_iso: string;
};

export default function useDeleteProbeLocation(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();
  const queryCache = useQueryCache();

  return useMutation(({ country_iso }: DeleteLocationRequestData) => axios.delete(
    `/cms/api/locations/${country_iso}`,
  ).then(({ data }) => data),
  {
    ...options,
    onSuccess: () => queryCache.invalidateQueries('locations')
  });
}
