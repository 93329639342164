import _ from 'lodash';
import React, {
  useEffect, useRef, useState,
} from 'react';

import clsx from 'clsx';

import {
  Table as BootstrapTable,
} from 'react-bootstrap';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  ITableProps,
} from './interfaces';

import './style.scss';

import TablePagination from './TablePagination';
import TableActions from './TableActions';
import TableHeader from './TableHeader';

import useCustomTable from './useCustomTable';

const Table = (props: ITableProps) => {
  const tableContainerRef = useRef(null);

  const {
    actionsPosition,
    columns: columnsProp,
    customGlobalFilter,
    data = [],
    DeleteButtonProps,
    disableActions,
    disablePagination,
    hideDeleteButton,
    hideExportButton,
    hideNewButton,
    hideRefreshButton,
    hideSelectCheckbox,
    initialState,
    itemsPerPage,
    maxHeight: maxHeightProp,
    NewButtonProps,
    onSelectedRowsChange,
    onStateChange,
    RefreshButtonProps,
    rowActions,
    selectable,
    setSelectedAll,
    setSelectedRow,
    showSettingsMenu,
    skipReset,
    stickyActions,
    stickyHeader,
    updateCellData,
  } = props;

  const {
    // table,
    // getState,
    columns,
    getTableProps,
    getTableBodyProps,
    headers,
    headerGroups,
    prepareRow,
    // pagination
    rows,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
    pageOptions,
    pageCount,
    selectedFlatRows,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setColumnOrder,
    // global filtering (searching)
    setGlobalFilter,
    toggleAllRowsSelected,
    toggleHideAllColumns,
    visibleColumns,
  } = useCustomTable({
    actionsPosition,
    columns: columnsProp,
    data,
    disablePagination,
    hideSelectCheckbox,
    initialState,
    itemsPerPage,
    onStateChange,
    rowActions,
    selectable,
    setSelectedAll,
    setSelectedRow,
    skipReset,
    updateCellData,
  });

  const [, setSelected] = useState();

  useEffect(() => {
    if (_.isFunction(onSelectedRowsChange)) {
      setSelected((prevState: any) => {
        const newSelectedProbes = _.map(selectedFlatRows, 'original');
        if (!_.isEqual(prevState, newSelectedProbes)) {
          onSelectedRowsChange(_.map(selectedFlatRows, 'original'));
          return newSelectedProbes;
        }
        return prevState;
      });
    }
  }, [selectedFlatRows]);

  if (customGlobalFilter && globalFilter !== customGlobalFilter) {
    console.log('setting global');
    setGlobalFilter(customGlobalFilter);
  }

  let maxHeight;

  if (maxHeightProp) {
    maxHeight = maxHeightProp;
  } else if (stickyHeader && _.some(visibleColumns, ['id', '__actions'])) {
    maxHeight = `${44 + 10 * 60}px`;
  } else if (stickyHeader) {
    maxHeight = `${44 + 10 * 48}px`;
  }

  return (
    <>
      {!disableActions && (
        <DndProvider backend={HTML5Backend}>
          <TableActions
            columns={headers}
            columnOrder={_.map(headers, 'id')}
            data={rows}
            DeleteButtonProps={DeleteButtonProps}
          // ExportButtonProps={ExportButtonProps}
            globalFilter={globalFilter || ''}
            gotoPage={gotoPage}
            hideDeleteButton={hideDeleteButton}
            hideExportButton={page.length === 0 || hideExportButton}
            hideNewButton={hideNewButton}
            hideRefreshButton={hideRefreshButton}
          // hideSearchInput={hideSearchInput}
            NewButtonProps={NewButtonProps}
            RefreshButtonProps={RefreshButtonProps}
          // SearchInputProps={SearchInputProps}
            setColumnOrder={setColumnOrder}
            setGlobalFilter={setGlobalFilter}
            showSettingsMenu={showSettingsMenu}
            toggleHideAllColumns={toggleHideAllColumns}
          />
        </DndProvider>
      )}
      <DndProvider backend={HTML5Backend}>
        <div className="position-relative table-responsive" ref={tableContainerRef} style={{ maxHeight }}>
          <BootstrapTable
            hover
            {...getTableProps()}
          >
            {_.filter(columns, (col:any) => col.isVisible).length !== 0
              ? (
                <caption>
                  {`Showing ${rows.length ? pageSize * pageIndex || 1 : 0} to ${Math.min(pageSize * (pageIndex + 1), rows.length)} of ${rows.length} entries`}
                </caption>
              )
              : null }
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr className={clsx({ 'table-sticky-header': stickyHeader })} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableHeader
                      key={column.id}
                      column={column}
                      columnOrder={_.map(headers, 'id')}
                      setColumnOrder={setColumnOrder}
                      stickyActions={stickyActions}
                    />
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);

                return (
                  <tr
                    onClick={() => {
                      if (selectable && hideSelectCheckbox) {
                        toggleAllRowsSelected(false);
                        row?.toggleRowSelected?.();
                      }
                    }}
                    style={{ backgroundColor: selectable && hideSelectCheckbox && row.isSelected ? '#f0f0f0' : 'inherit' }}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => (
                      <td
                        className={clsx({
                          selection: cell?.column?.id === 'selection',
                          'table-sticky-td-left': cell?.column?.id === '__actions' && stickyActions,
                          'table-sticky-td-right': cell?.column?.id === '__selection' && stickyActions,
                        })}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell', { editable: true })}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </BootstrapTable>
        </div>
        {!disablePagination && (
        <>
          { visibleColumns.length !== 0
            ? (
              <TablePagination
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageOptions={pageOptions}
                pageCount={pageCount}
                pageIndex={pageIndex}
                rows={rows.length}
                setPageSize={setPageSize}
                pageSize={pageSize}
                pageSizesArray={itemsPerPage}
              />
            )
            : <div style={{ color: '#d3006c' }}>Please select at least one column</div>}
        </>
        )}
      </DndProvider>
    </>
  );
};

export default Table;
