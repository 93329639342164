import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

interface FileUploadButtonProps {
  onFileSelected: (file: File) => void;
  accept?: string;
  buttonText?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  size?: 'small' | 'large';
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  onFileSelected,
  accept = '.csv, .json, .xlsx, .xls',
  buttonText = 'Upload File',
  variant = 'secondary',
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      onFileSelected(selectedFile);
    }
  };

  return (
    <>
      <Button variant={variant} onClick={handleButtonClick}>
        {buttonText}
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }} // Hide the actual file input
        accept={accept}
        onChange={handleFileChange}
      />
    </>
  );
};
FileUploadButton.propTypes = {
  onFileSelected: PropTypes.func.isRequired,
  accept: PropTypes.string,
  buttonText: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
};

export default FileUploadButton;
