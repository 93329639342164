import { useEffect } from 'react';
import { WindowsManagerGenericProvider } from 'components/WindowManager/WindowManager';
import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from './oidcConfig';
import './scss/telekom-extended-scss/telekom-extended-styles.scss';

import ConfirmProvider from './context/Confirm/ConfirmProvider';
import 'iframe-resizer/js/iframeResizer.contentWindow';
import MainRouter from './routes/MainRouter';
import AlertsProvider from './context/alerts/AlertsState';
import ApiState from './context/backendData/ApiState';
import GherkinState from './context/gherkinData/GherkinState';

const App = () => {
  if (process.env.NODE_ENV === 'production') console.log = () => {};

  useEffect(() => {
    const handleMessageEvent = (event: any) => {
      if (event.data === 'loginSuccess') {
        const isInIframe = window.top !== window.self;

        if (isInIframe) {
          window.location.reload();
        }
      }
    };

    window.addEventListener('message', handleMessageEvent, false);

    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, []);

  return (
    <AuthProvider {...oidcConfig}>
      <AlertsProvider>
        <ApiState>
          <ConfirmProvider>
            <GherkinState>
              <WindowsManagerGenericProvider>
                <MainRouter />
              </WindowsManagerGenericProvider>
            </GherkinState>
          </ConfirmProvider>
        </ApiState>
      </AlertsProvider>
    </AuthProvider>
  );
};

export default App;
