import React from 'react';
import { exportToCSV, exportToExcel, exportToJSON } from 'components/FileHandler/parsers/fileExporter';
import { Dropdown } from 'react-bootstrap';

const templateData = [
  {
    iccid: null,
    msisdn: null,
    imsi: null,
    tariff: null,
    wnw: null,
    op_wnw: null,
    psp: null,
    wholesale_id: null,
    itg_id: null,
    secret: null,
    name: null,
    origin: null,
    prepaid: false,
    type: null,
    sim_type: null,
    tags: [],
    live: true,
    lab: false,
  }
];

const Downloader = () => {
  const filename = 'SIM_template';

  const handleExport = (format: 'json' | 'csv' | 'xlsx') => {
    switch (format) {
      case 'json':
        exportToJSON(templateData, `${filename}_json`);
        break;
      case 'csv':
        exportToCSV(templateData, `${filename}_csv`);
        break;
      case 'xlsx':
        exportToExcel(templateData, `${filename}_xlsx`);
        break;
      default:
        console.error('Unsupported export format');
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
        Download Template
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleExport('xlsx')}>xlsx</Dropdown.Item>
        <Dropdown.Item onClick={() => handleExport('csv')}>csv</Dropdown.Item>
        <Dropdown.Item onClick={() => handleExport('json')}>json</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Downloader;
