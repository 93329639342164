import React, { useEffect, useState, useRef } from 'react';
import {
  Alert, Form,
} from 'react-bootstrap';
import { ScaleButton, ScaleCheckbox, ScaleModal, ScaleTextField, ScaleDropdownSelect, ScaleDropdownSelectItem } from '@telekom/scale-components-react';
import useForm from '../../../../hooks/useForm';
import useAddProbeAndEditLocation from '../../../../hooks/services/probe/useAddProbeAndEditLocation';
import { Probe } from '../../../../hooks/services/probe/interfaces';

interface IModalProps {
  onHide: () => void;
  setErrMessage: (message: string) => void;
  setMessage: (message: string) => void;
  locations: any;
}

type FormErrors = {
  location?: string,
  probe_alias?: string,
  probe_name?: string,
  platform?: string,
  IP?: string,
};

const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;

const AddProbeModal = ({
  onHide, setErrMessage, setMessage, locations,
}: IModalProps) => {
  const [showError, setShowError] = useState(true);
  const [opened, setOpened] = useState(false);

  const [previousLocation, setPreviousLocation] = useState('');
  const [previousIP, setPreviousIP] = useState('');
  const [previousProbeName, setPreviousProbeName] = useState('');
  const [previousProbeAlias, setPreviousProbeAlias] = useState('');

  const formRef = useRef<HTMLFormElement>(null);
  let Button = '';

  const [addProbe, { error }] = useAddProbeAndEditLocation({
    onSuccess: () => {
      setMessage('New probe has been added!');
      if (Button === 'create') onHide();
    },
    onError: () => setErrMessage('Failed to create probe!'),
  });

  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  useEffect(() => {
    setOpened(true);
  }, []);

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues, resetValues,
  } = useForm({
    initialValues: {
      probe_alias: '',
      probe_name: '',
      platform: '',
      OS: '',
      IP: '',
      VPN: false,
      poe: true,
      location_latitude: '',
      location_longitude: '',
      ras_password: '',
      ras_port: '',
      ras_protocol: '',
      ras_username: '',
    },
    onSubmit: () => {
      const probe: Probe = {
        probe_name: formValues.probe_name,
        probe_alias: formValues.probe_alias,
        platform: 'Raspberry',
        OS: 'Linux',
        IP: formValues.IP,
        VPN: formValues.VPN,
        poe: formValues.poe,
        location_latitude: formValues.location_latitude,
        location_longitude: formValues.location_longitude,
        ras_password: formValues.ras_password,
        ras_port: formValues.ras_port,
        ras_protocol: formValues.ras_protocol,
        ras_username: formValues.ras_username,
        status: 'offline',
      };
      addProbe({ countryIso: formValues?.location ?? previousLocation, probe });
      resetValues();
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if ((!values?.location || values?.location === '') && !previousLocation) {
        errors.location = 'Please fill in location.';
      }
      if (!values?.probe_alias || values?.probe_alias === '') {
        errors.probe_alias = 'Please fill in probe alias.';
      }
      if (/[_ ]/.test(values?.probe_alias)) {
        errors.probe_alias = '"_" and " " are not allowed';
      }
      if (!values?.probe_name || values?.probe_name === 0) {
        errors.probe_name = 'Please fill in probe name.';
      }
      if (/[_ ]/.test(values?.probe_name)) {
        errors.probe_name = '"_" and " " are not allowed';
      }
      if (!values?.IP || values?.IP === '') {
        errors.IP = 'Please fill in IP.';
      }
      if (values?.IP && !ipv4Regex.test(values?.IP)) {
        errors.IP = 'Please fill in correct IP.';
      }
      return errors;
    },
  });

  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  return (
    <ScaleModal
      heading="Add new probe"
      opened={opened}
      size="small"
      onScale-close={onHide}
      onScale-before-close={() => setOpened(false)}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        {error?.message && showError && (
        <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
          {error?.message}
        </Alert>
        )}
        <div style={{ display: 'flex', margin: '10px 0' }}>
          <ScaleDropdownSelect
            onScale-change={handleChange('location')}
            label="Location *"
            value={formValues?.location ?? previousLocation}
            helperText={submitted ? formErrors?.location : ''}
            style={{ margin: '0 8px 8px 3px', flex: '1' }}
          >
            {locations?.map((loc:any) => (
              <ScaleDropdownSelectItem key={loc.country_iso} value={loc.country_iso}>
                {loc.country_name}
              </ScaleDropdownSelectItem>
            ))}
          </ScaleDropdownSelect>
          <ScaleTextField
            id="IP"
            label="IP address *"
            invalid={submitted && Boolean(formErrors?.IP)}
            helperText={(submitted ? formErrors?.IP : '')}
            name="IP"
            onChange={handleChange('IP')}
            onScale-change={handleChange('IP')}
            required
            type="text"
            value={previousIP || formValues?.IP}
            style={{ flex: '1' }}
          />
        </div>
        <div style={{ display: 'flex', margin: '10px 0' }}>
          <ScaleTextField
            id="probe_name"
            label="Name *"
            invalid={submitted && Boolean(formErrors?.probe_name)}
            helperText='"_" and " " are not allowed'
            name="probe_name"
            onChange={handleChange('probe_name')}
            onScale-change={handleChange('probe_name')}
            required
            type="text"
            value={previousProbeName || formValues?.probe_name}
            style={{ margin: '0 8px 8px 3px', flex: '1' }}
          />
          <ScaleTextField
            id="probe_alias"
            label="Alias *"
            invalid={submitted && Boolean(formErrors?.probe_alias)}
            helperText='"_" and " " are not allowed'
            name="probe_alias"
            onChange={handleChange('probe_alias')}
            onScale-change={handleChange('probe_alias')}
            required
            type="text"
            value={previousProbeAlias || formValues?.probe_alias}
            style={{ flex: '1' }}
          />
        </div>
        <div style={{ display: 'flex', margin: '15px 0' }}>
          <ScaleCheckbox
            id="VPN"
            label="VPN connected"
            onClick={handleChange('VPN')}
            value={formValues?.VPN}
            checked={formValues?.VPN}
            style={{ margin: '0 8px 0 3px', flex: '1' }}
          />
          <ScaleCheckbox
            id="poe"
            label="PoE connected"
            onClick={handleChange('poe')}
            value={formValues?.poe}
            checked={formValues?.poe}
            style={{ flex: '1' }}
          />
        </div>
      </Form>
      <ScaleButton slot="action" variant="secondary" onClick={onHide}>Cancel</ScaleButton>
      <ScaleButton
        slot="action"
        variant="secondary"
        disabled={
          (!formValues?.IP
          || !formValues?.probe_name
          || !formValues?.probe_alias
          || previousIP === formValues?.IP
          || previousProbeName === formValues?.probe_name
          || previousProbeAlias === formValues?.probe_alias) && JSON.stringify(formErrors) !== '{}'
                }
        onClick={
        () => {
          formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
          Button = 'create_new';
          if (!previousLocation) {
            setPreviousLocation(formValues?.location);
          }
          setPreviousIP(formValues?.IP);
          setPreviousProbeName(formValues?.probe_name);
          setPreviousProbeAlias(formValues?.probe_alias);
        }
       }
      >Create & New
      </ScaleButton>
      <ScaleButton
        slot="action"
        disabled={
          (!formValues?.IP
          || !formValues?.probe_name
          || !formValues?.probe_alias
          || previousIP === formValues?.IP
          || previousProbeName === formValues?.probe_name
          || previousProbeAlias === formValues?.probe_alias) && JSON.stringify(formErrors) !== '{}'
                }
        onClick={() => {
          formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
          Button = 'create';
        }}
      >Create
      </ScaleButton>
    </ScaleModal>
  );
};

export default AddProbeModal;
