import React, { useEffect, useState, useRef } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { ScaleButton, ScaleCheckbox, ScaleDropdownSelect, ScaleDropdownSelectItem, ScaleIconActionRestart, ScaleIconActionPower, ScaleIconActionRemove, ScaleTextField } from '@telekom/scale-components-react';
import useForm from '../../../../../hooks/useForm';
import useEditProbe from '../../../../../hooks/services/probe/useEditProbe';
import useDeleteProbe from '../../../../../hooks/services/probe/useDeleteProbe';
import { Probe } from '../../../../../hooks/services/probe/interfaces';
import useConfirm from '../../../../../hooks/useConfirm';
import useRebootComponent from '../../../../../hooks/services/powerControl/useRebootComponent';
import useResetComponent from '../../../../../hooks/services/powerControl/useResetComponent';
import useEditProbeLocation from '../../../../../hooks/services/probe/useEditProbeLocation';
import ScaleTag from '../../../scale/StyledScaleTag';

interface IProbeDetailProps {
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
  getProbes: any;
  rebootableComponents: any;
  powercycleableComponents: any;
  locations: any;
  isDisabledReset: any;
  setIsDisabledReset: any;
  isDisabledReboot: any;
  setIsDisabledReboot: any;
  selectedProbe: any;
}

type FormErrors = {
  probe_alias?: string,
  platform?: string,
  IP?: string,
};

const EditProbe = ({
  setErrMessage, setMessage, getProbes, rebootableComponents, powercycleableComponents,
  isDisabledReset, setIsDisabledReset, isDisabledReboot,
  setIsDisabledReboot, selectedProbe, locations
}: IProbeDetailProps) => {
  const [showError, setShowError] = useState(true);
  const [location, setLocation] = useState(selectedProbe?.country_iso);

  const formRef = useRef<HTMLFormElement>(null);

  const disableButton = (button: any, uuid: any) => {
    if (button === 'reset') {
      if (!isDisabledReset?.find((val: any) => val
        .uuid === uuid)) {
        setIsDisabledReset([...isDisabledReset || [], {
          uuid,
          time: Date.now()
        }]);
      }
    } else if (button === 'reboot') {
      if (!isDisabledReboot?.includes(uuid)) {
        setIsDisabledReboot([...isDisabledReboot || [], {
          uuid,
          time: Date.now()
        }]);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const time = Date.now();
      if (isDisabledReset?.filter((item: any) => time > item.time + (30000))) {
        setIsDisabledReset(isDisabledReset?.filter((item: any) => time < (item.time + 30000)));
      }
      if (isDisabledReboot?.filter((item: any) => time > item.time + (30000))) {
        setIsDisabledReboot(isDisabledReboot?.filter((item:any) => time < (item.time + 30000)));
      }
      getProbes();
    }, 5000);
    return () => clearInterval(interval);
  }, [isDisabledReset, isDisabledReboot]);

  const [editProbe, { error }] = useEditProbe({
    onSuccess: () => {
      setMessage(`Probe ${selectedProbe?.probe_alias} has been updated!`);
      getProbes();
    },
    onError: () => setErrMessage('Failed to update probe!'),
  });

  const [assignProbeLocation] = useEditProbeLocation({
    onSuccess: () => {
      setMessage(`Moved probe ${selectedProbe?.probe_alias} to ${locations?.find((item: any) => item.country_iso === location)?.country_name}!`);
      getProbes();
    },
    onError: () => setErrMessage('Failed to move the probe!'),
  });

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues,
    touched, reset
  } = useForm({
    initialValues: {
      probe_alias: selectedProbe?.probe_alias,
      probe_name: selectedProbe?.probe_name,
      platform: selectedProbe?.platform,
      OS: selectedProbe?.OS,
      IP: selectedProbe?.IP,
      VPN: selectedProbe?.VPN,
      poe: selectedProbe?.poe,
      location_latitude: selectedProbe?.location_latitude,
      location_longitude: selectedProbe?.location_longitude,
      ras_password: selectedProbe?.ras_password,
      ras_port: selectedProbe?.ras_port,
      ras_protocol: selectedProbe?.ras_protocol,
      ras_username: selectedProbe?.ras_username,
      country_iso: selectedProbe?.country_iso,
    },
    onSubmit: () => {
      if (touched) {
        const probe: Probe = {
          probe_name: formValues.probe_name,
          probe_alias: formValues.probe_alias,
          platform: formValues.platform,
          OS: 'Linux',
          IP: formValues.IP,
          VPN: formValues.VPN,
          poe: formValues.poe,
          location_latitude: '',
          location_longitude: '',
          ras_password: formValues.ras_password,
          ras_port: formValues.ras_port,
          ras_protocol: formValues.ras_protocol,
          ras_username: formValues.ras_username,
          status: selectedProbe?.status,
          country_iso: selectedProbe?.country_iso,
        };
        editProbe({ probe, probeName: probe.probe_name });
      }
      if (location !== selectedProbe?.country_iso) {
        assignProbeLocation({
          probeName: formValues.probe_name,
          countryIso: location,
        });
      }
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if (!values?.probe_alias || values?.probe_alias === '') {
        errors.probe_alias = 'Please fill in probe alias.';
      }
      if (/[_ ]/.test(values?.probe_alias)) {
        errors.probe_alias = '"_" or " " not allowed';
      }
      if (!values?.platform || values?.platform === '') {
        errors.platform = 'Please fill in platform.';
      }
      if (!values?.IP || values?.IP === '') {
        errors.IP = 'Please fill in IP.';
      }
      return errors;
    },
  });

  const [deleteProbe] = useDeleteProbe(
    {
      onSuccess: () => {
        setMessage(`probe ${selectedProbe?.probe_alias} has been deleted`);
        getProbes();
      },
      onError: () => setErrMessage(`failed to delete probe ${selectedProbe?.probe_alias}`),
    },
  );

  // add confirm modal for delete probe
  const confirm = useConfirm();
  // const { data: devices } = useGetProbeDevices(probeData!.probe_name);
  const handleDeleteProbe = async ({ probeName }: any) => {
    confirm({
      body: `Are you sure to delete probe ${selectedProbe?.probe_alias}?`,
      onOk: () => deleteProbe({ probeName }),
      title: 'Delete probe',
    });
  };

  const [resetComponent] = useResetComponent(
    {
      onError: () => setErrMessage('Power Cycle of probe failed'),
    },
  );

  const handleResetComponent = (uuid: any) => {
    resetComponent({ uuid });
    disableButton('reset', uuid);
  };

  const [rebootComponent] = useRebootComponent(
    {
      onError: () => setErrMessage('Probe reboot failed'),
    },
  );

  const handleRebootComponent = (uuid: any) => {
    rebootComponent({ uuid });
    disableButton('reboot', uuid);
  };

  // show error if error changes
  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  useEffect(() => {
    reset();
    setLocation(selectedProbe?.country_iso);
  }, [selectedProbe]);

  return (
    <>
      <Form ref={formRef} onSubmit={handleSubmit}>
        {error?.message && showError && (
        <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
          {error?.message}
        </Alert>
        )}
        <div style={{ display: 'flex', margin: '10px 0' }}>
          <h4><strong>Probe</strong></h4>
          {selectedProbe && (selectedProbe?.status === 'online'
            ? <ScaleTag color="green" type="strong" style={{ margin: '0 5px' }}>Online</ScaleTag>
            : <ScaleTag color="red" type="strong" style={{ margin: '0 5px' }}>Offline</ScaleTag>)}
          {(Array.isArray(powercycleableComponents)
            ? powercycleableComponents.find((val: any) => val === selectedProbe?.uuid)
            : null) && (
            <ScaleButton
              size="small"
              variant="secondary"
              className="text-button-link ml-auto pr-2 pl-2"
              type="button"
              disabled={(isDisabledReset?.some((val: any) => val
                .uuid === selectedProbe?.uuid))}
              onClick={() => {
                handleResetComponent(selectedProbe?.uuid);
              }}
            >
              <ScaleIconActionRestart
                size={18}
                aria-label="power_cycle"
                style={{ cursor: 'pointer' }}
              />
              Power Cycle
            </ScaleButton>
          )}
          {(Array.isArray(rebootableComponents)
            ? rebootableComponents.find((val: any) => val === selectedProbe?.uuid)
            : null) && (
            <ScaleButton
              size="small"
              variant="secondary"
              className={(powercycleableComponents?.find((val: any) => val
              === selectedProbe?.uuid)) ? 'text-button-link pr-2 pl-2' : 'text-button-link ml-auto pr-2 pl-2'}
              type="button"
              disabled={(isDisabledReboot?.some(
                (val: any) => val
                  .uuid === selectedProbe?.uuid
              ))
              || (isDisabledReset?.some((val: any) => val
                .uuid === selectedProbe?.uuid))
              || selectedProbe?.status === 'offline'}
              onClick={() => {
                handleRebootComponent(selectedProbe?.uuid);
              }}
            >
              <ScaleIconActionPower
                size={18}
                aria-label="reboot"
                style={{ cursor: 'pointer' }}
              />
              Reboot
            </ScaleButton>
          )}
          <ScaleButton
            size="small"
            variant="secondary"
            type="button"
            className={(rebootableComponents?.find((val: any) => val
        === selectedProbe?.uuid)) ? 'text-button-link pr-2 pl-2' : 'text-button-link ml-auto pr-2 pl-2'}
            onClick={() => (
              handleDeleteProbe({ probeName: selectedProbe?.probe_name })
            )}
            disabled={selectedProbe?.devices.length !== 0 || !selectedProbe}
            icon-only
          >
            <div style={{ color: 'red', display: 'flex' }}>
              <ScaleIconActionRemove
                size={18}
                aria-label="delete"
                style={selectedProbe?.devices.length !== 0 || !selectedProbe ? { color: 'grey' } : { color: 'red' }}
              />
            </div>
          </ScaleButton>
        </div>
        <div style={{ display: 'flex', margin: '10px 0' }}>
          <ScaleDropdownSelect
            onScale-change={(e) => setLocation(e.target.value)}
            value={location || selectedProbe?.country_iso}
            label="Location *"
            disabled={!selectedProbe}
            style={{ margin: '0 3px', flex: '1' }}
          >
            {locations?.map((loc:any) => (
              <ScaleDropdownSelectItem key={loc.country_iso} value={loc.country_iso}>
                {loc.country_name}
              </ScaleDropdownSelectItem>
            ))}
          </ScaleDropdownSelect>
          <ScaleTextField
            id="IP"
            label="IP address *"
            invalid={submitted && Boolean(formErrors?.IP)}
            helperText={submitted ? formErrors?.IP : ''}
            name="IP"
            onChange={handleChange('IP')}
            onScale-change={handleChange('IP')}
            required
            type="text"
            value={formValues?.IP}
            style={{ margin: '0 3px', flex: '1' }}
            disabled={!selectedProbe}
          />
        </div>
        <div style={{ display: 'flex', margin: '10px 0' }}>
          <ScaleTextField
            id="probe_name"
            label="Name *"
            invalid={submitted && Boolean(formErrors?.probe_name)}
            helperText={submitted ? formErrors?.probe_name : ''}
            name="probe_name"
            onScale-change={handleChange('probe_name')}
            required
            type="text"
            value={formValues?.probe_name}
            style={{ margin: '0 3px', flex: '1' }}
            readonly
          />
          <ScaleTextField
            id="probe_alias"
            label="Alias *"
            invalid={submitted && Boolean(formErrors?.probe_alias)}
            helperText={submitted ? formErrors?.probe_alias : ''}
            name="probe_alias"
            onChange={handleChange('probe_alias')}
            onScale-change={handleChange('probe_alias')}
            required
            type="text"
            value={formValues?.probe_alias}
            style={{ margin: '0 3px', flex: '1' }}
            disabled={!selectedProbe}
          />
        </div>
        <div style={{ display: 'flex', margin: '10px 0' }}>
          <ScaleCheckbox
            id="VPN"
            label="VPN connected"
            onClick={handleChange('VPN')}
            value={formValues?.VPN}
            checked={formValues?.VPN}
            style={{ margin: '0 3px' }}
            disabled={!selectedProbe}
          />
          <ScaleCheckbox
            id="poe"
            label="PoE connected"
            onClick={handleChange('poe')}
            value={formValues?.poe}
            checked={formValues?.poe}
            style={{ margin: '0 3px' }}
            disabled={!selectedProbe}
          />
        </div>
      </Form>
      <div style={{ display: 'flex', margin: '10px 0' }}>
        <ScaleButton
          slot="action"
          size="small"
          variant="secondary"
          type="button"
          className="text-button-link ml-auto pr-2 pl-2"
          onClick={() => {
            reset();
            setLocation(selectedProbe?.country_iso);
          }}
          disabled={(location === selectedProbe?.country_iso
            && formValues?.IP === selectedProbe?.IP
            && formValues?.probe_alias === selectedProbe?.probe_alias
            && formValues?.platform === selectedProbe?.platform
            && formValues?.VPN === selectedProbe?.VPN
            && formValues?.poe === selectedProbe?.poe
          )}
        >
          Discard
        </ScaleButton>
        <ScaleButton
          slot="action"
          size="small"
          variant="primary"
          className="text-button-link pr-2 pl-2"
          onClick={() => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
          disabled={!selectedProbe || (location === selectedProbe?.country_iso
              && formValues?.IP === selectedProbe?.IP
              && formValues?.probe_alias === selectedProbe?.probe_alias
              && formValues?.platform === selectedProbe?.platform
              && formValues?.VPN === selectedProbe?.VPN
              && formValues?.poe === selectedProbe?.poe
          )}
        >
          Save
        </ScaleButton>
      </div>
    </>
  );
};

export default EditProbe;
