import { ScaleTag } from '@telekom/scale-components-react';
import styled from 'styled-components';

const StyledScaleTag = styled(ScaleTag)<{ textColor?: string, backgroundColor?: string }>`
  &::part(base) {
    color: ${({ textColor = 'white' }) => textColor};
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
`;

export default StyledScaleTag;
