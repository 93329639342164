import React from 'react';
import PropTypes from 'prop-types';

import { Col, Container, Row } from 'react-bootstrap';

type AdministrationLayoutProps = {
  children: React.ReactNode;
  sidebar: React.ReactNode;
};

const AdministrationNewLayout = ({ sidebar, children }: AdministrationLayoutProps) => (
  <>
    <Container className="d-flex flex-grow-1" fluid>
      <div style={{ maxWidth: '300px' }}>
        {sidebar}
      </div>
      <Container className="d-flex flex-column">
        <Row className="flex-grow-1">
          <Col className="d-flex flex-column">
            {children}
          </Col>
        </Row>
      </Container>

    </Container>
  </>
);

AdministrationNewLayout.propTypes = {
  children: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
};

export default AdministrationNewLayout;
