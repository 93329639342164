import _ from 'lodash';

import React, { useMemo, useEffect } from 'react';

import {
  useColumnOrder,
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useRowSelect,
} from 'react-table';

import { Form } from 'react-bootstrap';

import DefaultColumnFilter from './filters/DefaultColumnFilter';
import multiValueFilterFn from './filterTypes/multiValueFilter';

import TableRowActions from './TableRowActions';

const useCustomTable = ({
  actionsPosition,
  columns,
  data,
  disablePagination,
  hideSelectCheckbox,
  initialState,
  itemsPerPage,
  onStateChange: handleStateChange,
  rowActions,
  selectable,
  setSelectedAll,
  setSelectedRow,
  skipReset,
  updateCellData,
}: any) => {
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      multiValue: multiValueFilterFn,
      // Or, override the default text filter to use
      // "startWith"
    }),
    [],
  );

  // hacky way to disable pagination so that new useTable
  // without usePagination is not needed as this is only difference
  let pageSize = 10;
  if (disablePagination) {
    pageSize = data?.length || initialState?.pageSize || pageSize;
  } else if (initialState?.pageSize) {
    pageSize = initialState?.pageSize;
  } else if (itemsPerPage?.[0]) {
    // eslint-disable-next-line
    pageSize = itemsPerPage[0];
  }

  const table = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        pageIndex: 0,
        ...initialState,
        pageSize,
      },
      updateCellData,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      autoResetSelectedRows: false,
    },
    useColumnOrder,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((visibleColumns: any) => {
        let columnsWithUtilities = _.cloneDeep(visibleColumns);

        if (selectable && !hideSelectCheckbox) {
          columnsWithUtilities = [
            ...columnsWithUtilities,
            {
              id: '__selection',
              defaultCanFilter: false,
              defaultCanSort: false,
              disableFilters: true,
              disableGlobalFilter: true,
              disableSortBy: true,
              // Header: 'Selected',
              width: 50,
              maxWidth: 50,
              Cell: ({ row }: any) => (
                <Form.Check
                  checked={row.isSelected}
                  key={row.id}
                  type="checkbox"
                  inline
                  aria-label="Select"
                  label={<span className="sr-only">Select</span>}
                  onChange={(e: any) => {
                    row.getToggleRowSelectedProps().onChange(e);
                    if (setSelectedRow) {
                      setSelectedRow(row);
                    }
                  }}
                />
              ),
              Header: ({
                getToggleAllRowsSelectedProps,
                isAllRowsSelected,
              }: any) => (
                <Form.Check
                  checked={isAllRowsSelected}
                  type="checkbox"
                  inline
                  aria-label="Select all"
                  label={<span className="sr-only">Select all</span>}
                  onChange={(e: any) => {
                    getToggleAllRowsSelectedProps().onChange(e);
                    if (setSelectedAll) {
                      setSelectedAll(!isAllRowsSelected);
                    }
                  }}
                />
              ),
            },

          ];
        }

        if (rowActions) {
          if (actionsPosition === 'left') {
            columnsWithUtilities = [
              {
                id: '__actions',
                defaultCanFilter: false,
                defaultCanSort: false,
                disableFilters: true,
                disableGlobalFilter: true,
                disableSortBy: true,
                Header: () => <span className="sr-only">Actions</span>,
                width: '1px',
                // eslint-disable-next-line
                Cell: ({ row }: any) => (
                  <TableRowActions actions={rowActions?.items} row={row} />
                ),
              },
              ...columnsWithUtilities,
            ];
          } else {
            columnsWithUtilities = [
              ...columnsWithUtilities,
              {
                id: '__actions',
                defaultCanFilter: false,
                defaultCanSort: false,
                disableFilters: true,
                disableGlobalFilter: true,
                disableSortBy: true,
                Header: 'Actions',
                width: '1px',
                // eslint-disable-next-line
                Cell: ({ row }: any) => (
                  <TableRowActions actions={rowActions?.items} row={row} />
                ),
              },
            ];
          }
        }

        return columnsWithUtilities;
      });
    }
  );
  const { rows, toggleRowSelected } = table;

  // helper function to get attribute from object
  function hasOwnProperty<X extends {}, Y extends PropertyKey>(
    obj: X, prop: Y
  ): obj is X & Record<Y, unknown> {
    // eslint-disable-next-line no-prototype-builtins
    return obj.hasOwnProperty(prop);
  }

  useEffect(() => {
    console.log(`skipreset: ${skipReset}`);
    rows.forEach(({ id, original }) => {
      if (hasOwnProperty(original, 'isSelected')) {
        toggleRowSelected(id, !!original.isSelected);
      }
    });
  }, []);

  useEffect(() => {
    if (_.isFunction(handleStateChange)) {
      handleStateChange(_.omit(table?.state, ['filters', 'selectedRowIds', 'pageIndex', 'globalFilter']));
    }
  }, [table?.state]);

  return table;
};

export default useCustomTable;
