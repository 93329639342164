import React, { useState, useEffect } from 'react';
import useAlerts from 'hooks/useAlerts';
import useGetProbes from 'hooks/services/probe/useGetProbes';
import useGetProbeLocations from '../../../hooks/services/probe/location/useGetProbeLocations';
import SplitPageLayout from '../../../components/layout/SplitPageLayout';
import ProbeTable from './ProbeTable';
import ComponentDetail from './ComponentDetail';

const ProbeManagement = (props: any) => {
  const { location: { state } } = props;
  const { showAlert } = useAlerts();
  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [editProbe, setEditProbe] = useState<any>();
  const [location, setLocation] = useState(state || 'all');

  const { data: probes, refetch: getProbes } = useGetProbes();
  const { data: locations } = useGetProbeLocations();

  const [selectedProbe] = probes?.filter((item:any) => item?.probe_name === editProbe?.probe_name)
   || [];

  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errMessage) {
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errMessage, showAlert]);

  return (
    <SplitPageLayout
      left={(
        <ProbeTable
          probes={probes}
          locations={locations}
          setEditProbe={setEditProbe}
          location={location}
          setLocation={setLocation}
        />
    )}
      right={(
        <ComponentDetail
          setMessage={setMessage}
          setErrMessage={setErrMessage}
          getProbes={getProbes}
          selectedProbe={selectedProbe}
          locations={locations}
          probes={probes}
        />
    )}
    />
  );
};

export default ProbeManagement;
