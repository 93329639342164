/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  ScaleMenuFlyout,
  ScaleMenuFlyoutList,
  ScaleMenuFlyoutItem,
  ScaleIconActionMore,
  ScaleButton,
  ScaleTooltip,
} from '@telekom/scale-components-react';
import './style.scss';
import useAlerts from 'hooks/useAlerts';
import useConfirm from 'hooks/useConfirm';
import useDeleteProbeLocation from 'hooks/services/probe/location/useDeleteProbeLocation';
import { Link, Route } from 'react-router-dom';
import useGetProbeLocations from 'hooks/services/probe/location/useGetProbeLocations';
import RenameLocationModal from './RenameLocationModal';
import ScaleTag from './scale/StyledScaleTag';

type CardProps = {
  iso: string;
  name: string;
  online: number;
  offline: number;
  mobileProbes: number;
  ipPhonesProbes: number;
  modemProbes: number;
  probesLength: number;
};

const Card = ({ iso, name, online, offline,
  mobileProbes, ipPhonesProbes, modemProbes, probesLength }: CardProps) => {
  const { data: locations, refetch: getLocations } = useGetProbeLocations();

  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [showRenameLocationModal, setRenameLocationModal] = useState(false);

  const { showAlert } = useAlerts();

  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errMessage) {
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errMessage, showAlert]);

  const [deleteLocation] = useDeleteProbeLocation(
    {
      onSuccess: () => {
        setMessage('location has been deleted');
        // getLocations();
      },
      onError: () => setErrMessage('unable to delete location'),
    },
  );
  // add confirm modal for delete location
  const confirm = useConfirm();
  const handleDeleteLocation = async () => {
    if (online > 0 || offline > 0) {
      setErrMessage('The location has assigned probes, you cannot delete it!');
    } else {
      confirm({
        body: 'Are you sure to delete location?',
        onOk: () => deleteLocation({ country_iso: iso }),
        title: 'Delete location',
      });
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link to={{ pathname: '/administrationNew/probes', state: iso }}><span className="card-title" style={{ color: 'black', margin: '0 5px' }}>{name}</span></Link>
          {(iso !== 'all') && (
          <ScaleMenuFlyout direction="bottom-left">
            <ScaleButton slot="trigger" variant="secondary" size="small" icon-only>
              <ScaleIconActionMore accessibility-title="more" selected style={{ transform: 'rotate(90deg)' }} />
            </ScaleButton>
            <ScaleMenuFlyoutList>
              <ScaleMenuFlyoutItem
                onScale-select={() => {
                  setRenameLocationModal(true);
                }}
              >
                Rename
              </ScaleMenuFlyoutItem>
              {probesLength > 0 && (
              <ScaleTooltip content="The location has assigned probes.">
                <ScaleMenuFlyoutItem
                  disabled
                  onScale-select={() => {
                    handleDeleteLocation();
                  }}
                >Delete
                </ScaleMenuFlyoutItem>
              </ScaleTooltip>
              )}
              {probesLength === 0 && (
                <ScaleMenuFlyoutItem
                  onScale-select={() => {
                    handleDeleteLocation();
                  }}
                >Delete
                </ScaleMenuFlyoutItem>
              )}
            </ScaleMenuFlyoutList>
          </ScaleMenuFlyout>
          )}
        </div>
        {showRenameLocationModal
          && (
            <RenameLocationModal
              onHide={() => setRenameLocationModal(false)}
              setMessage={setMessage}
              setErrMessage={setErrMessage}
              getLocations={getLocations}
              country_iso={iso}
              name={name}
            />
          )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {offline > 0
          && (
            <ScaleTooltip content="No. of offline devices">
              <ScaleTag color="red" type="strong" style={{ margin: '0 5px' }}>{offline} offline</ScaleTag>
            </ScaleTooltip>
          )}
            {(offline === 0 && online >= 0)
          && (
            <ScaleTooltip content="No. of online devices">
              <ScaleTag color="green" type="strong" style={{ margin: '0 5px' }}>{online} online</ScaleTag>
            </ScaleTooltip>
          )}
            <ScaleTooltip content="No. of mobile probes">
              <ScaleTag textColor="black" color="standard" type="standard" style={{ margin: '0 2px 0 90px' }}>{mobileProbes}</ScaleTag>
            </ScaleTooltip>
            <ScaleTooltip content="No. of IP-Phone probes">
              <ScaleTag textColor="black" color="standard" type="standard" style={{ margin: '0 2px' }}>{ipPhonesProbes}</ScaleTag>
            </ScaleTooltip>
            <ScaleTooltip content="No. of modem probes">
              <ScaleTag textColor="black" color="standard" type="standard" style={{ margin: '0 2px' }}>{modemProbes}</ScaleTag>
            </ScaleTooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
