/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useMemo, useState } from 'react';

import { Button, Form, Modal } from 'react-bootstrap';

import Table from 'components/Table';

import useAlerts from 'hooks/useAlerts';
import useUserTableSettings from 'hooks/useUserTableSettings';

import useAddSubscriber from 'hooks/services/simManagement/subscriber/useAddSubscriber';
import useGetSubscribersWithType from 'hooks/services/simManagement/subscriber/useGetSubscribersWithType';
import useEditSubscriber from 'hooks/services/simManagement/subscriber/useEditSubscriber';
import { Subscriber } from 'hooks/services/simManagement/interfaces';
import FileHandler from 'components/FileHandler/FileHandler';
import subscriberSchema from 'components/FileHandler/parsers/subscriberSchema';

const ImportSimCardsModal = ({ onHide, show }: any) => {
  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const { showAlert } = useAlerts();
  const userTableSettings = useUserTableSettings('import-sim-cards');
  const { data: subscribers, refetch: getSimCards } = useGetSubscribersWithType();
  const [importedSimData, setImportedSimData] = useState<any[] | undefined>([]);

  const handleDataParsed = <T,>(parsedData: T[]) => {
    setImportedSimData(parsedData);
  };

  const omitColumns = [
    'import_action',
    'id',
    'modified',
    'position',
    'status',
    'network',
    'mapping',
    'assigned_to',
  ];

  // success or error message rendering
  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errMessage) {
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errMessage, showAlert]);

  const [addSubscriber] = useAddSubscriber({
    onSuccess: () => {
      setMessage('SIMs have been saved!');
      getSimCards();
      onHide();
    },
    onError: (err: any) => setErrMessage(`Failed to save SIMs: ${err}`),
  });

  const [editSubscriber] = useEditSubscriber({
    onSuccess: () => {
      setMessage('SIMs have been saved!');
      getSimCards();
      onHide();
    },
    onError: (err: any) => setErrMessage(`Failed to save SIMs: ${err}`),
  });

  const processedData = useMemo(() => {
    if (!subscribers || !importedSimData) return [];
    return importedSimData.map((sim) => ({
      ...sim,
      import_action: subscribers?.find((sub) => sub.iccid === sim.iccid)
        ? 'update'
        : 'create',
    }));
  }, [importedSimData, subscribers]);

  const handleUpdateSubscriber = async (subscriber: Subscriber) => {
    const existingSubscriber = subscribers?.find(
      (sub) => sub.iccid === subscriber.iccid,
    );

    if (existingSubscriber) {
      const filterd = Object.keys(existingSubscriber).filter((key) => !omitColumns.includes(key));

      const oldSubscribers = Object.entries(filterd).reduce(
        (acc, [key, value]) => {
          if (value !== null && value !== '') {
            acc[key] = value;
          }
          return acc;
        },
        {} as Subscriber,
      );

      if (existingSubscriber.id) {
        await editSubscriber({
          id: existingSubscriber.id,
          sim: { ...oldSubscribers, ...subscriber },
        });
      }
    }
  };

  const handleAddSims = async () => {
    const promises = processedData.map(async (subscriber: Subscriber) => {
      const action = subscriber.import_action as 'create' | 'update';

      const filtered = Object.keys(subscriber).filter((key) => !omitColumns.includes(key));

      const newSubscriber = Object.entries(filtered).reduce((acc, [key, value]) => {
        if (value !== null && value !== '') {
          acc[key] = value;
        }
        return acc;
      }, {} as Subscriber);

      if (action === 'create') {
        await addSubscriber({ sim: newSubscriber });
      } else if (action === 'update') {
        await handleUpdateSubscriber(newSubscriber);
      }
    });

    await Promise.all(promises);
  };

  const tableColumns = useMemo(
    () => [
      {
        accessor: 'import_action',
        Header: 'Action',
        disableFilters: true,
      },
      {
        accessor: 'iccid',
        Header: 'ICCID',
        disableFilters: true,
      },
      {
        accessor: 'msisdn',
        Header: 'MSISDN',
        disableFilters: true,
      },
      {
        accessor: 'imsi',
        Header: 'IMSI',
        disableFilters: true,
      },
      {
        accessor: 'tags',
        Header: 'TAGS',
        disableFilters: true,
      },
      {
        accessor: 'tariff',
        Header: 'TARIFF',
        disableFilters: true,
      },
      {
        accessor: 'wnw',
        Header: 'WNW',
        disableFilters: true,
      },
      {
        accessor: 'op_wnw',
        Header: 'OP WNW',
        disableFilters: true,
      },
      {
        accessor: 'psp',
        Header: 'PSP',
        disableFilters: true,
      },
      {
        accessor: 'wholesale_id',
        Header: 'WHOLESALE_ID',
        disableFilters: true,
      },
      {
        accessor: 'itg_id',
        Header: 'ITG ID',
        disableFilters: true,
      },
      {
        accessor: 'secret',
        Header: 'PIN',
        disableFilters: true,
      },
      {
        accessor: 'name',
        Header: 'NAME',
        disableFilters: true,
      },
      {
        accessor: 'origin',
        Header: 'ORIGIN',
        disableFilters: true,
      },
      {
        accessor: 'prepaid',
        Header: 'PREPAID',
        disableFilters: true,
      },
      {
        accessor: 'type',
        Header: 'TYPE',
        disableFilters: true,
      },
      {
        accessor: 'sim_type',
        Header: 'SIM_TYPE',
        disableFilters: true,
      }],
    [],
  );

  return (
    <Modal size="lg" show={show} onHide={onHide} backdrop="static">
      <Modal.Header>
        <Modal.Title>Import SIM Cards</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>SIM cards import file</Form.Label>
            <FileHandler
              onDataParsed={handleDataParsed}
              schema={subscriberSchema}
            />
            <Form.Text>
              SIM Manager accepts SIM card data to be uploaded only in a
              specific form. Therefore you can download example .xlsx file.
            </Form.Text>
          </Form.Group>
        </Form>
        {tableColumns && processedData && processedData.length > 0 && (
          <Table
            columns={tableColumns}
            data={processedData}
            disableActions
            initialState={userTableSettings?.initialState}
            onStateChange={userTableSettings.updateTableSettings}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleAddSims}>
          Import
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportSimCardsModal;
