/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { ScaleButton, ScaleCheckbox, ScaleIconActionRestart, ScaleIconActionPower, ScaleIconActionRemove, ScaleTextField } from '@telekom/scale-components-react';
import useForm from '../../../../../hooks/useForm';
import useEditProbeDevice from '../../../../../hooks/services/probe/useEditProbeDevice';
import useDeleteProbeDevice from '../../../../../hooks/services/probe/useDeleteProbeDevice';
import useConfirm from '../../../../../hooks/useConfirm';
import useRebootComponent from '../../../../../hooks/services/powerControl/useRebootComponent';
import useHardRestart from '../../../../../hooks/services/powerControl/useHardRestart';
import ScaleTag from '../../../scale/StyledScaleTag';

interface IDeviceDetailProps {
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
  probeName: string;
  getProbes: any;
  rebootableComponents: any;
  powercycleableComponents: any;
  isDisabledReset: any;
  isDisabledReboot: any;
  selectedProbe: any;
}

type FormErrors = {
  name?: string;
  serial?: string,
  type?: string,
  adboe?: boolean,
  version?: string,
  status?: string,
  exclusive?: string,
  rooted?: string,
  IP?: string,
};

const EditDevice = ({
  setErrMessage, setMessage, probeName, getProbes,
  rebootableComponents, powercycleableComponents, isDisabledReset,
  isDisabledReboot, selectedProbe
}: IDeviceDetailProps) => {
  const [showError, setShowError] = useState(true);
  const [isDisabledRebootDevice, setIsDisabledRebootDevice] = useState<Array<{ uuid: any
  , time: any }>>();
  const [isDisabledHardRestart, setIsDisabledHardRestart] = useState<Array<{ name: any
  , time: any }>>();

  const formRef = useRef<HTMLFormElement>(null);

  const disableButton = (button: any, uuid: any) => {
    if (button === 'rebootDevice') {
      if (!isDisabledRebootDevice?.includes(uuid)) {
        setIsDisabledRebootDevice([...isDisabledRebootDevice || [], {
          uuid,
          time: Date.now()
        }]);
      }
    } else if (button === 'hardRestart') {
      if (!isDisabledHardRestart?.includes(uuid)) {
        setIsDisabledHardRestart([...isDisabledHardRestart || [], {
          name: uuid,
          time: Date.now()
        }]);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const time = Date.now();
      if (isDisabledRebootDevice?.filter((item) => time > item.time + (30000))) {
        setIsDisabledRebootDevice(isDisabledRebootDevice
          ?.filter((item) => time < (item.time + 30000)));
      }
      if (isDisabledHardRestart?.filter((item) => time > item.time + (60000))) {
        setIsDisabledHardRestart(isDisabledHardRestart
          ?.filter((item) => time < (item.time + 60000)));
      }
      getProbes();
    }, 5000);
    return () => clearInterval(interval);
  }, [isDisabledRebootDevice, isDisabledHardRestart]);

  const [editProbeDevice, { error }] = useEditProbeDevice({
    onSuccess: () => {
      setMessage('Device has been updated!');
      getProbes();
    },
    onError: () => setErrMessage('Failed to update device'),
  });

  const [deleteDevice] = useDeleteProbeDevice(
    {
      onSuccess: () => {
        setMessage(`device ${selectedProbe?.devices[0]?.name} has been deleted`);
        getProbes();
      },
      onError: () => setErrMessage(`failed to delete device ${selectedProbe?.devices[0]?.name}`),
    },
  );

  const confirm = useConfirm();
  const handleDeleteDevice = async ({ device }: any) => {
    confirm({
      body: `Are you sure to delete the device ${selectedProbe?.devices[0]?.name}?`,
      onOk: () => deleteDevice({ probeName, device }),
      title: `Delete device ${selectedProbe?.devices[0]?.name}`,
    });
  };

  // show error if error changes
  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  const [rebootDevice] = useRebootComponent(
    {
      onError: () => setErrMessage('Device restart failed'),
    },
  );

  const handleRebootDevice = (uuid: any) => {
    rebootDevice({ uuid });
    disableButton('rebootDevice', uuid);
  };

  const [hardRestart] = useHardRestart(
    {
      onError: () => setErrMessage('Arduino reboot failed'),
    },
  );

  const handleHardRestart = (name: any) => {
    hardRestart({ probeName, parameters: {} });
    disableButton('hardRestart', name);
  };

  const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues, reset
  } = useForm({
    initialValues: {
      device_id: selectedProbe?.devices?.[0]?.device_id,
      name: selectedProbe?.devices?.[0]?.name,
      serial: selectedProbe?.devices?.[0]?.serial,
      type: selectedProbe?.devices?.[0]?.type,
      adboe: selectedProbe?.devices?.[0]?.adboe,
      version: selectedProbe?.devices?.[0]?.version,
      IP: selectedProbe?.devices?.[0]?.IP,
      exclusive: selectedProbe?.devices?.[0]?.exclusive,
      rooted: selectedProbe?.devices?.[0]?.rooted,
    },
    onSubmit: () => {
      editProbeDevice({
        probeName,
        device: {
          device_id: selectedProbe?.devices[0]?.device_id.toString(),
          name: formValues.name,
          serial: formValues.serial,
          type: selectedProbe?.devices?.[0]?.type,
          adboe: formValues.adboe,
          version: formValues.version ? formValues.version : '',
          IP: formValues.IP,
          exclusive: formValues.exclusive,
          rooted: formValues.rooted,
        },
      });
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if (!values?.name || values?.name === 0) {
        errors.name = 'Please fill in device name.';
      }
      if (!values?.serial || values?.serial === '') {
        errors.serial = 'Please fill in serial.';
      }
      if (!values?.type || values?.type === 0) {
        errors.type = 'Please fill in type.';
      }
      if (values?.type === 'IP_Phone' && (!values?.IP || values?.IP === '' || !ipv4Regex.test(values?.IP))) {
        errors.IP = 'Please fill in IP.';
      }
      return errors;
    },
  });

  useEffect(() => {
    reset();
  }, [probeName]);

  return (
    <>
      <Form ref={formRef} onSubmit={handleSubmit}>
        {error?.message && showError && (
        <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
          {error?.message}
        </Alert>
        )}
        <div style={{ display: 'flex', margin: '10px 0' }}>
          <h4><strong>Device</strong></h4>
          {selectedProbe?.devices[0]?.type === 'Android' ? ((selectedProbe?.devices[0]?.status === 'online' && selectedProbe?.devices[0]?.stf_status === 'online' && selectedProbe?.status === 'online')
            ? <ScaleTag color="green" type="strong" style={{ margin: '0 5px' }}>Online</ScaleTag>
            : <ScaleTag color="red" type="strong" style={{ margin: '0 5px' }}>Offline</ScaleTag>)
            : ((selectedProbe?.devices[0]?.status === 'online' && selectedProbe?.status === 'online'))
              ? <ScaleTag color="green" type="strong" style={{ margin: '0 5px' }}>Online</ScaleTag>
              : <ScaleTag color="red" type="strong" style={{ margin: '0 5px' }}>Offline</ScaleTag>}
          {(powercycleableComponents?.find((val: any) => val
          === selectedProbe?.devices[0]?.uuid)) && (
          <ScaleButton
            size="small"
            variant="secondary"
            className="text-button-link ml-auto pr-2 pl-2"
            type="button"
            disabled={(isDisabledHardRestart?.some(
              (val: any) => val
                .name === selectedProbe?.probe_name
            ))
                  || selectedProbe?.status === 'offline'}
            onClick={() => {
              handleHardRestart(selectedProbe?.probe_name);
            }}
          >
            <ScaleIconActionRestart
              size={18}
              aria-label="power_cycle"
              style={{ cursor: 'pointer' }}
            />
            Power Cycle
          </ScaleButton>
          )}
          {(rebootableComponents?.find((val: any) => val
          === selectedProbe?.devices[0]?.uuid)) && (
          <ScaleButton
            size="small"
            variant="secondary"
            className="text-button-link pr-2 pl-2"
            type="button"
            disabled={(isDisabledRebootDevice?.some(
              (val: any) => val
                .uuid === selectedProbe?.devices[0]?.uuid
            ))
                || (isDisabledReboot?.some((val: any) => val
                  .uuid === selectedProbe?.uuid))
                || (isDisabledReset?.some((val: any) => val
                  .uuid === selectedProbe?.uuid))
                || selectedProbe?.status === 'offline'
                || selectedProbe?.devices[0]?.status === 'offline'}
            onClick={() => {
              handleRebootDevice(selectedProbe?.devices[0]?.uuid);
            }}
          >
            <ScaleIconActionPower
              size={18}
              aria-label="reboot"
              style={{ cursor: 'pointer' }}
            />
            Reboot
          </ScaleButton>
          )}
          <ScaleButton
            size="small"
            variant="secondary"
            className={(rebootableComponents?.find((val: any) => val
              === selectedProbe?.devices[0]?.uuid)) ? 'text-button-link pr-2 pl-2' : 'text-button-link ml-auto pr-2 pl-2'}
            type="button"
            onClick={() => (
              handleDeleteDevice({ device: selectedProbe?.devices[0] })
            )}
            icon-only
          >
            <div style={{ color: 'red', display: 'flex' }}>
              <ScaleIconActionRemove
                size={18}
                aria-label="delete"
                style={{ color: 'red' }}
              />
            </div>
          </ScaleButton>
        </div>
        <div style={{ display: 'flex', margin: '10px 0' }}>
          <ScaleTextField
            id="type"
            label="Type *"
            invalid={submitted && Boolean(formErrors?.type)}
            helperText={submitted ? formErrors?.type : ''}
            name="type"
            onChange={handleChange('type')}
            onScale-change={handleChange('type')}
            required
            type="text"
            value={formValues?.type}
            style={{ margin: '0 3px', flex: '1' }}
            readonly
          />
          <ScaleTextField
            id="name"
            label="Name *"
            invalid={submitted && Boolean(formErrors?.name)}
            helperText={submitted ? formErrors?.name : ''}
            name="name"
            onChange={handleChange('name')}
            onScale-change={handleChange('name')}
            required
            type="text"
            value={formValues?.name}
            style={{ margin: '0 3px', flex: '1' }}
          />
        </div>
        <div style={{ display: 'flex', margin: '10px 0' }}>
          {(!(formValues?.type === 'IP_Phone' || formValues?.type === 'Analogue_Modem')) && (
          <>
            <ScaleTextField
              id="serial"
              label="Serial *"
              invalid={submitted && Boolean(formErrors?.serial)}
              helperText={(submitted && formErrors?.serial) || ((formValues?.type === 'Android' && 'add :5555 for ADBoE connected devices') || '')}
              name="serial"
              onChange={handleChange('serial')}
              onScale-change={handleChange('serial')}
              required
              type="text"
              value={formValues?.serial}
              style={{ margin: '0 3px', flex: '0.5' }}
            />
            <ScaleTextField
              id="version"
              label="OS version"
              invalid={submitted && Boolean(formErrors?.version)}
              helperText={submitted ? formErrors?.version : ''}
              name="version"
              onChange={handleChange('version')}
              onScale-change={handleChange('version')}
              required
              type="text"
              value={formValues?.version}
              style={{ margin: '0 3px', flex: '0.5' }}
            />
          </>
          )}
        </div>
        <div style={{ display: 'flex', margin: '10px 0' }}>
          {(formValues?.type === 'Android' || !formValues?.type) && (
          <ScaleCheckbox
            id="adboe"
            label="ADBoE connected"
            onChange={handleChange('adboe')}
            onClick={handleChange('adboe')}
            value={formValues?.adboe}
            checked={formValues?.adboe}
            style={{ margin: '8px 3px', flex: '0.5' }}
          />
          )}
          {(formValues?.type === 'IP_Phone') && (
          <ScaleTextField
            id="IP"
            label="IP address"
            invalid={submitted && Boolean(formErrors?.IP)}
            helperText={submitted ? formErrors?.IP : ''}
            name="IP"
            onChange={handleChange('IP')}
            onScale-change={handleChange('IP')}
            type="text"
            value={formValues?.IP}
            style={{ margin: '0 3px', flex: '0.5' }}
          />
          )}
        </div>
      </Form>
      <div style={{ display: 'flex', margin: '10px 0' }}>
        <ScaleButton
          slot="action"
          size="small"
          variant="secondary"
          type="button"
          className="text-button-link ml-auto pr-2 pl-2"
          onClick={() => reset()}
          disabled={(formValues?.type === selectedProbe?.devices[0]?.type
            && formValues?.name === selectedProbe?.devices[0]?.name
            && formValues?.serial === selectedProbe?.devices[0]?.serial
            && formValues?.version === selectedProbe?.devices[0]?.version
            && formValues?.adboe === selectedProbe?.devices[0]?.adboe
            && formValues?.IP === selectedProbe?.devices[0]?.IP
          )}
        >
          Discard
        </ScaleButton>
        <ScaleButton
          slot="action"
          size="small"
          variant="primary"
          className="text-button-link pr-2 pl-2"
          onClick={() => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
          disabled={(formValues?.type === selectedProbe?.devices[0]?.type
            && formValues?.name === selectedProbe?.devices[0]?.name
            && formValues?.serial === selectedProbe?.devices[0]?.serial
            && formValues?.adboe === selectedProbe?.devices[0]?.adboe
            && formValues?.version === selectedProbe?.devices[0]?.version
            && formValues?.IP === selectedProbe?.devices[0]?.IP
          )}
        >
          Save
        </ScaleButton>
      </div>
    </>
  );
};

export default EditDevice;
