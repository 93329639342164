import React, { useMemo, useState, useEffect } from 'react';
import {
  ScaleButton,
  ScaleChip,
  ScaleIconActionAdd,
  ScaleIconContentSimCard,
  ScaleIconDeviceMobilePhoneInsurance,
  ScaleIconUserFileBussinesUsers,
  ScaleTextField,
  ScaleTooltip
} from '@telekom/scale-components-react';
import { Container, Row, Col } from 'react-bootstrap';
import useGetProbeLocations from 'hooks/services/probe/location/useGetProbeLocations';
import AddLocationModal from 'pages/Administration/ProbeManagement/AddLocationModal';
import useAlerts from 'hooks/useAlerts';
import useGetSimCards from '../../hooks/services/simManagement/sim/useGetSimCards';
import useGetProbes from '../../hooks/services/probe/useGetProbes';
import useGetUsers from '../../hooks/services/user/useGetUsers';
import Card from './Card';

const Locations = () => {
  const { data: locations, refetch: getLocations } = useGetProbeLocations();
  const { data: simCards } = useGetSimCards();
  const { data: probes } = useGetProbes();
  const { data: users } = useGetUsers();

  const { showAlert } = useAlerts();
  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [search, setSearch] = useState('');

  const offlineDevicesAndroidAll = useMemo(() => probes?.filter((probe: any) => probe.devices && probe.devices.length > 0 && probe.devices[0]?.type === 'Android' && (probe.devices[0]?.status !== 'online' || probe.devices[0]?.stf_status !== 'online' || probe.status !== 'online')).length || 0, [probes]);
  const offlineDevicesNonAndroidAll = useMemo(() => probes?.filter((probe: any) => probe.devices && probe.devices.length > 0 && probe.devices[0]?.type !== 'Android' && (probe.devices[0]?.status !== 'online' || probe.status !== 'online')).length || 0, [probes]);
  const onlineDevicesAndroidAll = useMemo(() => probes?.filter((probe: any) => probe.devices[0]?.type === 'Android' && probe.devices[0]?.status === 'online' && probe.devices[0]?.stf_status === 'online' && probe.status === 'online').length || 0, [probes]);
  const onlineDevicesNonAndroidAll = useMemo(() => probes?.filter((probe: any) => probe.devices[0]?.type !== 'Android' && probe.devices[0]?.status === 'online' && probe.status === 'online').length || 0, [probes]);

  const mobileProbesAll = useMemo(() => probes?.filter((probe: any) => probe.devices[0]?.type === 'Android' || probe.devices[0]?.type === 'iOS').length || 0, [probes]);
  const ipPhonesProbesAll = useMemo(() => probes?.filter((probe: any) => probe.devices[0]?.type === 'IP_Phone').length || 0, [probes]);
  const modemProbesAll = useMemo(() => probes?.filter((probe: any) => probe.devices[0]?.type === 'Analogue_Modem').length || 0, [probes]);

  const locationCards = useMemo(() => locations?.map((location: any) => ({
    iso: location.country_iso,
    name: location.country_name,
    offlineDevicesAndroid: probes?.filter((probe: any) => probe.devices && probe.devices.length > 0 && probe.country_iso === location.country_iso && probe.devices[0]?.type === 'Android' && (probe.devices[0]?.status !== 'online' || probe.devices[0]?.stf_status !== 'online' || probe.status !== 'online')).length || 0,
    offlineDevicesNonAndroid: probes?.filter((probe: any) => probe.devices && probe.devices.length > 0 && probe.country_iso === location.country_iso && probe.devices[0]?.type !== 'Android' && (probe.devices[0]?.status !== 'online' || probe.status !== 'online')).length || 0,
    onlineDevicesAndroid: probes?.filter((probe: any) => probe.country_iso === location.country_iso && probe.devices[0]?.type === 'Android' && probe.devices[0]?.status === 'online' && probe.devices[0]?.stf_status === 'online' && probe.status === 'online').length || 0,
    onlineDevicesNonAndroid: probes?.filter((probe: any) => probe.country_iso === location.country_iso && probe.devices[0]?.type !== 'Android' && probe.devices[0]?.status === 'online' && probe.status === 'online').length || 0,
    mobileProbes: probes?.filter((probe: any) => probe.country_iso === location.country_iso && (probe?.devices[0]?.type === 'Android' || probes?.devices?.[0]?.type === 'iOS')).length || 0,
    ipPhonesProbes: probes?.filter((probe: any) => probe.country_iso === location.country_iso && probe?.devices[0]?.type === 'IP_Phone').length || 0,
    modemProbes: probes?.filter((probe: any) => probe.country_iso === location.country_iso && probe?.devices[0]?.type === 'Analogue_Modem').length || 0,
  })), [locations, probes]);

  const handleSearchProbes = (e: any) => {
    setSearch(e.target.value ?? '');
  };

  const filteredCards = locationCards?.sort((a, b) => a.name.localeCompare(b.name))
    .filter((card: any) => card.name.toLowerCase()
      .includes(search.toLowerCase()));

  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errMessage) {
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errMessage, showAlert]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <ScaleButton
          size="small"
          variant="secondary"
          onClick={() => setShowLocationModal(true)}
        >
          <ScaleIconActionAdd />
          Location
        </ScaleButton>
        <div>
          <ScaleTooltip content="No. of probes">
            <ScaleChip type="dynamic" className="mx-1">
              <div slot="chip-icon">
                <ScaleIconDeviceMobilePhoneInsurance />
              </div>
              {probes?.length || 0}
            </ScaleChip>
          </ScaleTooltip>
          <ScaleTooltip content="No. of users">
            <ScaleChip type="dynamic" className="mx-1">
              <div slot="chip-icon">
                <ScaleIconUserFileBussinesUsers />
              </div>
              {users?.length || 0}
            </ScaleChip>
          </ScaleTooltip>
          <ScaleTooltip content="No. of subscribers">
            <ScaleChip type="dynamic" className="mx-1">
              <div slot="chip-icon">
                <ScaleIconContentSimCard />
              </div>
              {simCards?.length || 0}
            </ScaleChip>
          </ScaleTooltip>
        </div>
        <div>
          <ScaleTextField
            id="search"
            name="search"
            label="Search locations"
            onScale-change={(e: any) => {
              handleSearchProbes(e);
            }}
            type="text"
          />
        </div>
      </div>
      {showLocationModal
          && (
            <AddLocationModal
              onHide={() => setShowLocationModal(false)}
              setMessage={setMessage}
              setErrMessage={setErrMessage}
              getLocations={getLocations}
            />
          )}
      {locations && (
        <Container>
          <Row>
            <Col xs={6} sm={5} md={4} lg={4} xl={3} style={{ padding: '4px', minHeight: '140px' }}>
              <Card
                iso="all"
                name="All probes"
                offline={offlineDevicesAndroidAll + offlineDevicesNonAndroidAll}
                online={onlineDevicesAndroidAll + onlineDevicesNonAndroidAll}
                mobileProbes={mobileProbesAll}
                ipPhonesProbes={ipPhonesProbesAll}
                modemProbes={modemProbesAll}
                probesLength={0}
              />
            </Col>
          </Row>
          <Row>
            {filteredCards.map(({ iso, name, offlineDevicesAndroid, offlineDevicesNonAndroid,
              onlineDevicesAndroid, onlineDevicesNonAndroid,
              mobileProbes, ipPhonesProbes, modemProbes }: any) => (
                <Col key={iso} xs={6} sm={5} md={4} lg={4} xl={3} style={{ padding: '4px', minHeight: '140px' }}>
                  <Card
                    iso={iso}
                    name={name}
                    offline={offlineDevicesAndroid + offlineDevicesNonAndroid}
                    online={onlineDevicesAndroid + onlineDevicesNonAndroid}
                    mobileProbes={mobileProbes}
                    ipPhonesProbes={ipPhonesProbes}
                    modemProbes={modemProbes}
                    probesLength={probes?.filter(
                      (probe: any) => probe.country_iso === iso
                    ).length || 0}
                  />
                </Col>
            ))}
          </Row>
        </Container>
      )}
    </div>
  );
};

export default Locations;
