import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

export const LoginRedirectClose = () => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated) {
      window?.opener?.postMessage('loginSuccess', '*');
      window.close();
    }
  }, [auth.isAuthenticated]);

  return (
    <>
      <h1>Login Successful</h1>
      <p>Please close this tab to continue.</p>
    </>
  );
};

export default LoginRedirectClose;
