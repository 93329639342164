import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import ImportSimCardsModal from './ImportSimCardsModal';
import Downloader from '../../../../components/Downloader';

const ImportSimCardsTab = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Row>
        <Col className="col-xl-9 col-lg-8 col-md-6 col-sm-12 mb-3">
          <p>
            New subscribers can be added in these formats: json, xlsx, csv.
            You can download an empty template file here if you don&apos;t have one already.
          </p>
        </Col>
        <Col className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <div className="text-right">
            <Button
              className="mt-2"
              id="import-sim-cards"
              variant="primary"
              onClick={() => setShowModal(true)}
            >
              Import Subscribers
            </Button>
            <div>
              <Downloader />
            </div>
          </div>
        </Col>
      </Row>
      {showModal
      && (
      <ImportSimCardsModal
        onHide={() => setShowModal(false)}
        show={showModal}
      />
      )}
    </>
  );
};
export default ImportSimCardsTab;
