import {
  parseCSV,
  parseJSON,
  parseExcel,
} from './parsers/fileParser';
import FileUploadButton from './FileUploadButton';

interface FileHandlerProps<T> {
  onDataParsed: (
    data: T[],
    columns: { Header: string; accessor: string }[],
  ) => void;
  schema: Record<string, string>; // Schema for parsing
}

const FileHandler = <T,>({ onDataParsed, schema }: FileHandlerProps<T>) => {
  const handleFileSelected = (file: File) => {
    const fileType = file.type;

    const reader = new FileReader();
    reader.onload = () => {
      try {
        if (fileType === 'text/csv') {
          const { rows, columns } = parseCSV(reader.result as string);
          onDataParsed(
            rows,
            columns.map((key) => ({ Header: key, accessor: key })),
          );
        } else if (fileType === 'application/json') {
          const { rows, columns } = parseJSON(reader.result as string);
          onDataParsed(
            rows,
            columns.map((key) => ({ Header: key, accessor: key })),
          );
        } else if (
          fileType
            === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          || fileType === 'application/vnd.ms-excel'
        ) {
          const { rows, columns } = parseExcel(
            reader.result as ArrayBuffer,
            schema, // Pass the schema here
          );
          onDataParsed(
            rows,
            columns.map((key) => ({ Header: key, accessor: key })),
          );
        } else {
          console.error('Unsupported file type.');
        }
      } catch (error) {
        console.error('Error parsing file:', error);
      }
    };

    if (fileType === 'application/json') {
      reader.readAsText(file);
    } else if (
      fileType
        === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      || fileType === 'application/vnd.ms-excel'
    ) {
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsText(file);
    }
  };

  return (
    <FileUploadButton variant="secondary" onFileSelected={handleFileSelected} />
  );
};

export default FileHandler;
