import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { ScaleDivider, ScaleButton, ScaleIconActionAdd } from '@telekom/scale-components-react';
import EditProbe from './EditProbe';
import EditDevice from './EditDevice';
import EditSubscriber from './EditSubscriber';
import AddDeviceModal from './AddDeviceModal';
import useGetPowerControllers from '../../../../hooks/services/powerControl/useGetPowerControllers';

interface IComponentDetailProps {
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
  getProbes: any;
  selectedProbe: any;
  locations: any;
  probes: any;
}

const ComponentDetail = ({
  setErrMessage, setMessage, getProbes,
  selectedProbe, locations, probes,
}: IComponentDetailProps) => {
  const { data: rebootableComponents } = useGetPowerControllers('rebootable');
  const { data: powercycleableComponents } = useGetPowerControllers('powercyclable');

  const [isDisabledReset, setIsDisabledReset] = useState<Array<{ uuid: any, time: any }>>();
  const [isDisabledReboot, setIsDisabledReboot] = useState<Array<{ uuid: any, time: any }>>();

  const [showAddDeviceModal, setShowAddDeviceModal] = useState(false);

  return (
    <Container fluid>
      <EditProbe
        setMessage={setMessage}
        setErrMessage={setErrMessage}
        getProbes={getProbes}
        rebootableComponents={rebootableComponents}
        powercycleableComponents={powercycleableComponents}
        isDisabledReset={isDisabledReset}
        setIsDisabledReset={setIsDisabledReset}
        isDisabledReboot={isDisabledReboot}
        setIsDisabledReboot={setIsDisabledReboot}
        selectedProbe={selectedProbe}
        locations={locations}
      />
      <ScaleDivider />
      {(selectedProbe?.devices.length !== 0 && selectedProbe) && (
      <EditDevice
        setMessage={setMessage}
        setErrMessage={setErrMessage}
        getProbes={getProbes}
        probeName={selectedProbe?.probe_name}
        rebootableComponents={rebootableComponents}
        powercycleableComponents={powercycleableComponents}
        isDisabledReset={isDisabledReset}
        isDisabledReboot={isDisabledReboot}
        selectedProbe={selectedProbe}
      />
      )}
      {(selectedProbe?.devices.length === 0) && (
      <>
        <div style={{ display: 'flex', margin: '10px 0' }}>
          No device added to the probe yet.
          <ScaleButton
            size="small"
            variant="secondary"
            onClick={() => setShowAddDeviceModal(true)}
            className="text-button-link ml-auto pr-2 pl-2"
            disabled={!selectedProbe}
          >
            <ScaleIconActionAdd
              size={18}
              aria-label="add subscriber"
              style={{ cursor: 'pointer' }}
            />
            Device
          </ScaleButton>
        </div>
        <ScaleDivider />
      </>
      )}
      {showAddDeviceModal
        && (
          <AddDeviceModal
            onHide={() => setShowAddDeviceModal(false)}
            setMessage={setMessage}
            setErrMessage={setErrMessage}
            getProbes={getProbes}
            probeName={selectedProbe?.probe_name}
            probeIP={selectedProbe?.IP}
            probes={probes}
          />
        )}
      {(selectedProbe?.devices.length !== 0 && selectedProbe) && (
        <>
          <ScaleDivider />
          <EditSubscriber
            setMessage={setMessage}
            setErrMessage={setErrMessage}
            getProbes={getProbes}
            probeName={selectedProbe?.probe_name}
            selectedProbe={selectedProbe}
          />
        </>
      )}
    </Container>
  );
};

export default ComponentDetail;
