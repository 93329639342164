import React, { useMemo, useState, useEffect } from 'react';

import { Form } from 'react-bootstrap';
import { ScaleButton, ScaleModal } from '@telekom/scale-components-react';

import Table from 'components/Table';

import useUserTableSettings from 'hooks/useUserTableSettings';

import TableColumns from '../../../../SimColumns';

const hiddenColumns = [
  'msisdn',
  'imsi',
  'tariff',
  'wnw',
  'op_wnw',
  'psp',
  'wholesale_id',
  'itg_id',
  'secret',
  'name',
  'origin',
  'prepaid',
  'type',
  'sim_type',
  'modified',
  'position'
];

const AddSubscriberTable = (props : any) => {
  const {
    onHide,
    sims,
    checkedSim,
    setCheckedSim,
    selectedSim,
    deviceData,
  } = props;
  const [opened, setOpened] = useState(false);
  const userTableSettings = useUserTableSettings('edit-sim-cards', { hiddenColumns });

  const tableColumns = [
    ...TableColumns,
    {
      Header: 'Tags',
      accessor: 'tags',
      isSortable: false,
      Cell: ({ value }: any) => (
        <>
          {value?.join(', ')}
        </>
      )
    },
    {
      Header: 'Network',
      accessor: 'network',
      Cell: ({ value }: any) => (
        <>
          {value && value?.filter((val:any) => (['lab', 'live'].includes(val))).join(', ')}
        </>
      )
    },
  ];

  const tableData = sims?.filter((sim: any) => {
    switch (deviceData?.type) {
      case 'Android':
      case 'iOS':
        return sim.type === 'static';
        break;
      case 'Analogue_Modem':
        return sim.type === 'fixed_line';
        break;
      case 'IP_Phone':
        return sim.type === 'sip';
        break;
      default:
        return false;
        break;
    }
  })
    .map((simCard: any) => ({ checked: String(checkedSim) === String(simCard.id), ...simCard, network: [simCard?.lab && 'lab', simCard?.live && 'live'] }));

  const columns = useMemo(() => [
    {
      Header: 'Selected',
      accessor: 'checked',
      disableFilters: true,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      Cell: (props: any) => {
        const { cell: { row } } = props;
        const { id, checked } = row.original;
        return (
          <>
            <Form.Check
              defaultChecked={checked}
              defaultValue={id}
              id={`groups-sims.${id}`}
              key={`groups-sims.${id}`}
              inline
              aria-label="Select"
              onClick={() => ((id === checkedSim) ? setCheckedSim(null) : setCheckedSim(id))}
            />
          </>
        );
      },
    },
    ...tableColumns.map((item) => ({ ...item, disableFilters: true })),
  ], [tableColumns]);

  useEffect(() => {
    setOpened(true);
  }, []);

  return (
    <ScaleModal
      heading="Select static subscriber"
      opened={opened}
      size="large"
      onScale-close={() => { onHide(); setCheckedSim(selectedSim); }}
      onScale-before-close={() => setOpened(false)}
    >
      <>
        {userTableSettings?.isFetched && (
        <Table
          columns={columns}
          data={tableData ?? []}
          hideExportButton
          initialState={userTableSettings?.initialState}
          onStateChange={userTableSettings.updateTableSettings}
          skipReset={false}
          showSettingsMenu
        />
        )}
        <div style={{ display: 'flex', margin: '10px 0' }}>
          <ScaleButton variant="secondary" className="text-button-link ml-auto pr-2 pl-2" onClick={() => { onHide(); setCheckedSim(selectedSim); }}>Close</ScaleButton>
          <ScaleButton className="text-button-link pr-2 pl-2" disabled={!checkedSim} onClick={onHide}>Select</ScaleButton>
        </div>
      </>
    </ScaleModal>
  );
};

export default AddSubscriberTable;
