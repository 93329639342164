import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

type AdministrationLayoutProps = {
  left: React.ReactNode;
  right: React.ReactNode;
};

const SplitPageLayout = ({ left, right }: AdministrationLayoutProps) => (
  <Container className="d-flex flex-grow-1" fluid>
    <div className="d-flex flex-column flex-lg-row flex-grow-1">
      <div className="border-right" style={{ width: '800px' }}>
        {left}
      </div>
      <div className="d-flex  flex-grow-1" style={{ maxWidth: '800px', width: '600px' }}>
        <Container className="d-flex flex-column">
          <Row className="flex-grow-1">
            <Col className="d-flex flex-column">
              {right}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </Container>
);

SplitPageLayout.propTypes = {
  left: PropTypes.node.isRequired,
  right: PropTypes.node.isRequired,
};

export default SplitPageLayout;
