/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import {
  ScaleIconActionAdd,
  ScaleButton,
  ScaleDropdownSelect,
  ScaleDropdownSelectItem,
} from '@telekom/scale-components-react';
import useAlerts from 'hooks/useAlerts';
import AddProbeModal from 'pages/AdministrationNew/ProbeManagement/AddProbeModal';
import Table from 'components/Table';
import ScaleTag from '../../scale/StyledScaleTag';

interface IProbeTable {
  setEditProbe(value: any): void;
  probes: any;
  locations: any;
  location: any;
  setLocation(value: any): void;
}

const ProbeTable = ({
  setEditProbe, probes, locations, location, setLocation
}: IProbeTable) => {
  const { showAlert } = useAlerts();
  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [showAddProbeModal, setShowAddProbeModal] = useState(false);

  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errMessage) {
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errMessage, showAlert]);

  const tableColumns = useMemo(
    () => [
      {
        accessor: 'country_name',
        Header: 'Location',
        disableFilters: true,
      },
      {
        accessor: 'status',
        Header: 'Probe status',
        disableFilters: true,
        Cell: ({ value }: any) => (
          value === 'online' ? (
            <ScaleTag color="green" type="strong">
              Online
            </ScaleTag>
          ) : (
            <ScaleTag color="red" type="strong">
              Offline
            </ScaleTag>
          )
        ),
      },
      {
        accessor: 'device_status',
        Header: 'Device status',
        disableFilters: true,
        Cell: ({ value }: any) => (
          value !== ''
            ? (value === 'online' ? (
              <ScaleTag color="green" type="strong">
                Online
              </ScaleTag>
            ) : (
              <ScaleTag color="red" type="strong">
                Offline
              </ScaleTag>
            )) : ''
        ),
      },
      {
        accessor: 'probe_alias',
        Header: 'Alias',
        disableFilters: true,
      },
      {
        accessor: 'probe_name',
        Header: 'Name',
        disableFilters: true,
      },
      {
        accessor: 'type',
        Header: 'Type',
        disableFilters: true,
      },
      {
        accessor: 'device_model',
        Header: 'Device Model',
        disableFilters: true,
      },
    ],
    [],
  );

  const getDeviceStatus = (probe: any) => {
    if (!probe?.devices || probe.devices.length === 0) {
      return '';
    }
    if (probe.devices[0]?.type === 'Android') {
      return (probe.devices[0]?.status === 'online' && probe.devices[0]?.stf_status === 'online' && probe.status === 'online') ? 'online' : 'offline';
    }
    return (probe.devices[0]?.status === 'online' && probe.status === 'online') ? 'online' : 'offline';
  };

  const tableData = useMemo(
    () => {
      if (probes) {
        return probes
          .filter((item: any) => (location === 'all' || item.country_iso === location))
          .map((probe: any) => ({
            probe_alias: probe?.probe_alias || 'unknown',
            probe_name: probe?.probe_name,
            country_iso: probe?.country_iso,
            country_name: locations?.find((item: any) => item.country_iso === probe.country_iso)?.country_name || '',
            type: probe?.devices[0]?.type,
            device_model: probe?.devices[0]?.name,
            platform: probe?.platform,
            IP: probe?.IP,
            VPN: probe?.VPN,
            poe: probe?.poe,
            devices: probe?.devices[0],
            // eslint-disable-next-line no-nested-ternary
            device_status: getDeviceStatus(probe),
            uuid: probe?.uuid,
            status: probe?.status,
          }));
      }

      return [];
    },
    [probes, location, locations],
  );

  const handleSelectedRowsChange = useCallback((rows: any) => {
    console.log('setEditProbe', rows[0]);
    setEditProbe(rows[0]);
  }, []);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '1' }}>
          <ScaleButton
            size="small"
            variant="secondary"
            className="text-button-link pr-2 pl-2"
            onClick={() => setShowAddProbeModal(true)}
          >
            <ScaleIconActionAdd />
            Probe
          </ScaleButton>
        </div>
        {showAddProbeModal
        && (
          <AddProbeModal
            onHide={() => setShowAddProbeModal(false)}
            setMessage={setMessage}
            setErrMessage={setErrMessage}
            locations={locations}
          />
        )}
        <div style={{ flex: '1' }}>
          <ScaleDropdownSelect
            onScale-change={(e) => setLocation(e.target.value)}
            label="Locations"
            value={location}
          >
            <ScaleDropdownSelectItem key="all" value="all">
              All
            </ScaleDropdownSelectItem>
            {locations?.sort((a, b) => a.country_name.localeCompare(b.country_name))
              .map((loc:any) => (
                <ScaleDropdownSelectItem key={loc.country_iso} value={loc.country_iso}>
                  {loc.country_name}
                </ScaleDropdownSelectItem>
              ))}
          </ScaleDropdownSelect>
        </div>
      </div>
      <div className="p-3">
        <Table
          columns={tableColumns}
          data={tableData || []}
          hideSelectCheckbox
          initialState={{
            selectedRowIds: {
              0: true
            },
            hiddenColumns: 'probe_name'
          }}
          onSelectedRowsChange={handleSelectedRowsChange}
          selectable
          skipReset={false}
          hideExportButton
        />
      </div>
    </>
  );
};

export default ProbeTable;
