import React, { useEffect, useState, useRef } from 'react';
import {
  Alert, Form,
} from 'react-bootstrap';
import { ScaleButton, ScaleModal, ScaleTextField } from '@telekom/scale-components-react';
import useForm from '../../../../hooks/useForm';
import useAddProbeLocation from '../../../../hooks/services/probe/location/useAddProbeLocation';

interface ILocation {
  country_iso: string;
  country_name: string;
}

interface IModalProps {
  onHide: () => void;
  setErrMessage: (message: string) => void;
  setMessage: (message: string) => void;
  getLocations: any;
}

type FormErrors = {
  country_iso?: string;
  country_name?: string;
};

const AddLocationModal = ({
  onHide, setErrMessage, setMessage, getLocations,
}: IModalProps) => {
  const [showError, setShowError] = useState(true);
  const [opened, setOpened] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setOpened(true);
  }, []);

  const isoRegex = /^[a-zA-Z]{3}$/;
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

  const [addLocation, { error }] = useAddProbeLocation(
    {
      onSuccess: () => {
        setMessage('Added new location');
        getLocations();
        onHide();
      },
      onError: () => setErrMessage('unable to add client to location'),
    },
  );

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues,
  } = useForm({
    initialValues: {
      locationName: '',
    },
    onSubmit: (location: ILocation) => {
      addLocation(location);
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if (!values?.country_name || values?.country_name === '') {
        errors.country_name = 'Please fill in location name.';
      }
      if (values?.country_name && specialCharRegex.test(values?.country_name)) {
        errors.country_name = 'Special characters are not allowed';
      }
      if (!values?.country_iso || values?.country_iso === '') {
        errors.country_iso = 'Please fill in location ISO.';
      }
      if (values?.country_iso && !isoRegex.test(values?.country_iso)) {
        errors.country_iso = 'Must be a three letter ISO code.';
      }
      return errors;
    },
  });

  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  return (
    <ScaleModal
      heading="Add location"
      opened={opened}
      size="small"
      onScale-close={onHide}
      onScale-before-close={() => setOpened(false)}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        {error?.message && showError && (
        <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
          {error?.message}
        </Alert>
        )}
        <div style={{ display: 'flex', margin: '10px 0' }}>
          <ScaleTextField
            id="country_name"
            label="Name *"
            invalid={submitted && Boolean(formErrors?.country_name)}
            helperText="Special characters are not allowed"
            name="country_name"
            onChange={handleChange('country_name')}
            onScale-change={handleChange('country_name')}
            required
            type="text"
            value={formValues?.country_name}
            style={{ flex: 1, margin: '0 3px' }}
          />
          <ScaleTextField
            id="country_iso"
            label="ISO *"
            invalid={submitted && Boolean(formErrors?.country_iso)}
            helperText="Must be a three letter ISO code."
            name="country_iso"
            onChange={handleChange('country_iso')}
            onScale-change={handleChange('country_iso')}
            required
            type="text"
            value={formValues?.country_iso}
            style={{ flex: 1, margin: '0 3px' }}
          />
        </div>
      </Form>
      <ScaleButton slot="action" onClick={onHide}>Cancel</ScaleButton>
      <ScaleButton slot="action" onClick={() => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}>Add</ScaleButton>
    </ScaleModal>
  );
};

export default AddLocationModal;
