import React from 'react';
import { Link, Route } from 'react-router-dom';
import { ScaleSidebarNav, ScaleSidebarNavItem } from '@telekom/scale-components-react';
import PageTitle from 'components/PageTitle';
import AdministrationNewLayout from '../../components/layout/AdministrationNewLayout';
import Locations from './Locations';
import SimCards from '../Administration/SimCards';
import Attenuators from '../Administration/Attenuators';
import ProbeManagement from './ProbeManagement/ProbeManagement';
import UserManagement from '../Administration/UserManagement';
import Maintenance from '../Administration/Maintenance';
import SimArrays from '../Administration/SimArrays';
import Pools from '../Administration/Pools';

const menuItems = [
  { title: 'Locations', link: '/administrationNew', page: Locations },
  { title: 'Subscribers', link: '/administrationNew/sim-cards', page: SimCards },
  { title: 'Pools', link: '/administrationNew/pools', page: Pools },
  { title: 'SIM Arrays', link: '/administrationNew/sim-arrays', page: SimArrays },
  { title: 'Probes', link: '/administrationNew/probes', page: ProbeManagement },
  { title: 'Attenuators', link: '/administrationNew/attenuators', page: Attenuators },
  { title: 'Users & Groups', link: '/administrationNew/user-management', page: UserManagement },
  { title: 'Maintenance', link: '/administrationNew/maintenance', page: Maintenance },
];

const AdministrationNew = () => (
  <>
    <ol className="breadcrumb ml-4">
      <li className="breadcrumb-item"><Link to="/">TESSA</Link></li>
      <li className="breadcrumb-item"><Link to="/administrationNew">Administration</Link></li>
      {menuItems.map((menuItem) => (
        <Route
          key={menuItem.title}
          path={menuItem.link}
          exact
          render={() => <li className="breadcrumb-item" style={{ fontWeight: 'bold' }}>{menuItem.title}</li>}
        />
      ))}
    </ol>
    <div className="ml-4"><PageTitle>Administration</PageTitle></div>
    <AdministrationNewLayout
      sidebar={(
        <ScaleSidebarNav collapsible-label="Explore this topic">
          {menuItems.map((menuItem) => (
            <ScaleSidebarNavItem bold>
              <Link to={menuItem.link}>{menuItem.title}</Link>
            </ScaleSidebarNavItem>
          ))}
        </ScaleSidebarNav>
    )}
    >
      {menuItems.map((menuItem) => (
        <Route
          key={menuItem.title}
          path={menuItem.link}
          exact
          component={menuItem.page}
        />
      ))}
    </AdministrationNewLayout>
  </>
);

export default AdministrationNew;
