import { useState, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { Redirect } from 'react-router-dom';

export const LoginRedirect = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState < null | string >(null);

  useEffect(() => {
    const handleLogin = async () => {
      if (!auth.isAuthenticated && !auth.isLoading && !isLoading) {
        try {
          setIsLoading(true);
          await auth.signinRedirect({ redirect_uri: `${window.location.origin}/login-redirect-close` });
        } catch (err) {
          console.error('Error during sign-in redirect:', err);
          setError('Failed to authenticate. Please try again.');
          setIsLoading(false);
        }
      }
    };

    handleLogin();
  }, [auth, isLoading]);

  if (auth.isAuthenticated) {
    return <Redirect to="/login-redirect-close" />;
  }

  if (isLoading || auth.isLoading) {
    return <div>Authenticating...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return null;
};

export default LoginRedirect;
