import Papa from 'papaparse';
import * as XLSX from 'xlsx';

// Generic function for parsing CSV
export const parseCSV = (csv: string): { rows: any; columns: string[] } => {
  const parsed = Papa.parse(csv, { header: true, skipEmptyLines: true });
  const rows = parsed.data;

  if (rows.length > 0) {
    const columns = Object.keys(rows[0] as object);
    return { rows, columns };
  }

  return { rows: [], columns: [] };
};

// Generic function for parsing JSON
export const parseJSON = (
  jsonString: string,
): { rows: any[]; columns: string[] } => {
  const jsonData = JSON.parse(jsonString);

  if (Array.isArray(jsonData)) {
    const columns = jsonData.length > 0 ? Object.keys(jsonData[0]) : [];
    return { rows: jsonData, columns };
  }

  throw new Error('Invalid JSON format: Expected an array of objects.');
};

// Generic function for parsing JSON
export const parseExcel = (
  arrayBuffer: ArrayBuffer,
  schema: Record<string, string>, // Accept schema as parameter
): { rows: any; columns: string[] } => {
  const workbook = XLSX.read(arrayBuffer, { type: 'array' });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];

  // Parse the worksheet into JSON
  const rawRows: any = XLSX.utils.sheet_to_json(worksheet);

  // Preprocess rows based on schema
  const rows = rawRows.map((row:any) => {
    const updatedRow = { ...row } as Record<string, any>;
    // eslint-disable-next-line no-restricted-syntax
    for (const field in schema) {
      if (schema[field] === 'array' && typeof updatedRow[field] === 'string') {
        // Adjust parsing based on how arrays are represented
        updatedRow[field] = (updatedRow[field] as string)
          .split(',')
          .map((item) => item.trim());
      }
      // Handle other types if necessary
    }
    return updatedRow;
  });

  const columns = rows.length > 0 ? Object.keys(rows[0]) : [];
  return { rows, columns };
};
