import React, { useEffect, useState, useRef } from 'react';
import { Alert, Form, } from 'react-bootstrap';
import { ScaleButton, ScaleIconActionAdd, ScaleTextField, ScaleIconActionRemove, ScaleDivider } from '@telekom/scale-components-react';
import useGetSubscribers from 'hooks/services/simManagement/subscriber/useGetSubscribers';
import useForm from '../../../../../hooks/useForm';
import AddSubscriberModal from './AddSubscriberModal';
import useAddMultiplexer from '../../../../../hooks/services/probe/useAddMultiplexer';
import useRemoveMultiplexer from '../../../../../hooks/services/probe/useRemoveMultiplexer';
import useConfirm from '../../../../../hooks/useConfirm';
import useAssignDevice from '../../../../../hooks/services/simManagement/subscriber/useAssignDevice';
import useUnassignDevice from '../../../../../hooks/services/simManagement/subscriber/useUnassignDevice';

interface IProbeDetailProps {
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
  probeName: any;
  getProbes: any;
  selectedProbe: any;
}

const EditSubscriber = ({
  setErrMessage, setMessage, probeName, getProbes, selectedProbe
}: IProbeDetailProps) => {
  const [showError, setShowError] = useState(true);
  const formRef = useRef<HTMLFormElement>(null);
  const [showSubscriberModal, setShowSubscriberModal] = useState(false);

  const { data: sims } = useGetSubscribers();

  const [selectedSim, setSelectedSim] = useState<number | null>(
    selectedProbe?.devices[0]?.subscribers
      ? parseInt(Object.keys(selectedProbe?.devices[0]?.subscribers)[0], 10)
      : null
  );
  const [checkedSim, setCheckedSim] = useState<number | null>(
    selectedProbe?.devices[0]?.subscribers
      ? parseInt(Object.keys(selectedProbe?.devices[0]?.subscribers)[0], 10)
      : null
  );

  const [addMultiplexer, { error }] = useAddMultiplexer({
    onSuccess: () => {
      setMessage('Multiplexer has been added!');
      getProbes();
    },
    onError: () => setErrMessage('Failed to update device'),
  });

  const [assignDevice] = useAssignDevice({
    onSuccess: () => {
      setMessage('Subscriber assigned successfully');
      getProbes();
    },
    onError: () => {
      setErrMessage('Failed to assign subscriber');
    },
  });

  const [removeSubscriber] = useUnassignDevice(
    {
      onSuccess: () => {
        setMessage('Subscriber unassigned successfully');
        getProbes();
      },
      onError: () => setErrMessage('failed to unassign subscriber'),
    },
  );

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues,
    reset, touched
  } = useForm({
    initialValues: {
      multiplexer: selectedProbe?.devices[0]?.multiplexers[0],
    },
    onSubmit: () => {
      if (formValues?.multiplexer !== undefined && formValues?.multiplexer !== '') {
        addMultiplexer({
          probeName,
          deviceId: selectedProbe?.devices[0]?.device_id.toString(),
          multiplexers: formValues.multiplexer.trim(),
        });
      }
      if (selectedSim !== null && (checkedSim !== selectedSim)) {
        removeSubscriber({
          id: selectedSim,
          deviceId: selectedProbe.devices[0].device_id
        });
      }
      if (checkedSim !== selectedSim) {
        assignDevice({
          id: checkedSim,
          deviceId: selectedProbe.devices[0].device_id
        });
        getProbes();
      }
    },
    validate: () => {
    },
  });

  const [removeMultiplexer] = useRemoveMultiplexer(
    {
      onSuccess: () => {
        setMessage('multiplexer has been deleted');
        getProbes();
        formValues.multiplexer = '';
      },
      onError: () => setErrMessage('failed to delete multiplexer'),
    },
  );

  const confirm = useConfirm();

  const handleRemoveMultiplexer = async () => {
    confirm({
      body: 'Are you sure to delete multiplexer?',
      onOk: () => removeMultiplexer({
        probeName,
        device: selectedProbe?.devices[0],
        multiplexers: formValues.multiplexer.trim(),
      }),
      title: 'Delete multiplexer',
    });
  };

  const handleRemoveSubscriber = async () => {
    confirm({
      body: 'Are you sure you want to unassign the subscriber?',
      onOk: () => removeSubscriber({
        id: checkedSim,
        deviceId: selectedProbe.devices[0].device_id
      }),
      title: 'Unassign subscriber',
    });
  };

  // show error if error changes
  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  useEffect(() => {
    reset();
    setSelectedSim(selectedProbe?.devices[0]?.subscribers
      ? parseInt(Object.keys(selectedProbe?.devices[0]?.subscribers)[0], 10)
      : null);
    setCheckedSim(selectedProbe?.devices[0]?.subscribers
      ? parseInt(Object.keys(selectedProbe?.devices[0]?.subscribers)[0], 10)
      : null);
  }, [selectedProbe]);

  const subscriberValue = () => {
    if ((checkedSim === selectedSim) && selectedProbe?.devices[0]?.subscribers) {
      return selectedProbe?.devices[0]?.subscribers[Object
        .keys(selectedProbe?.devices[0]?.subscribers)[0]]?.msisdn;
    }
    if (checkedSim !== selectedSim) {
      return (sims?.filter((sim:any) => sim.id === checkedSim)[0]?.msisdn);
    }
    return '';
  };

  return (
    <>
      <Form ref={formRef} onSubmit={handleSubmit}>
        {error?.message && showError && (
          <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
            {error?.message}
          </Alert>
        )}
        {!(selectedProbe?.devices[0]?.type === 'IP_Phone' || selectedProbe?.devices[0]?.type === 'Analogue_Modem') && (
          <div style={{ display: 'flex', margin: '10px 0' }}>
            <ScaleTextField
              id="multiplexer"
              label="Multiplexer serial"
              name="multiplexer"
              onScale-change={handleChange('multiplexer')}
              type="text"
              readonly={selectedProbe?.devices[0]?.subscribers || checkedSim
                || selectedProbe?.devices[0]?.multiplexers[0]}
              value={formValues?.multiplexer}
              style={{ margin: '0 3px', flex: '1' }}
            />
            <ScaleButton
              size="small"
              variant="secondary"
              className="text-button-link pr-2 pl-2"
              type="button"
              style={{ margin: '0 3px', flex: '1' }}
              onClick={() => (
                handleRemoveMultiplexer()
              )}
              disabled={selectedProbe?.devices[0]?.type === 'Analogue_Modem' || selectedProbe?.devices[0]?.type === 'IP_Phone' || selectedProbe?.devices[0]?.subscribers || !selectedProbe?.devices[0]?.multiplexers[0]}
              icon-only
            >
              <div style={{ color: 'red', display: 'flex' }}>
                <ScaleIconActionRemove
                  size={18}
                  aria-label="delete"
                  style={selectedProbe?.devices[0]?.type === 'Analogue_Modem' || selectedProbe?.devices[0]?.type === 'IP_Phone' || selectedProbe?.devices[0]?.subscribers || !selectedProbe?.devices[0]?.multiplexers[0] ? { color: 'grey' } : { color: 'red' }}
                />
              </div>
            </ScaleButton>
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-start', margin: '10px 0' }}>
          <ScaleTextField
            id="subscriber"
            label="Subscriber"
            invalid={submitted && Boolean(formErrors?.subscriber)}
            helperText={submitted ? formErrors?.subscriber : ''}
            name="subscriber"
            type="text"
            value={subscriberValue()}
            style={{ margin: '0 3px', flex: '1.12' }}
            readonly
          />
          <ScaleButton
            size="small"
            variant="secondary"
            className="text-button-link pr-2 pl-2"
            type="button"
            style={{ margin: '0 3px', flex: '0.1' }}
            onClick={() => (
              handleRemoveSubscriber()
            )}
            disabled={!selectedProbe?.devices[0]?.subscribers}
            icon-only
          >
            <div style={{ color: 'red', display: 'flex' }}>
              <ScaleIconActionRemove
                size={18}
                aria-label="delete"
                style={!selectedProbe?.devices[0]?.subscribers ? { color: 'grey' } : { color: 'red' }}
              />
            </div>
          </ScaleButton>
          <ScaleButton
            size="small"
            variant="secondary"
            className="text-button-link pr-2 pl-2"
            type="button"
            style={{ margin: '0 3px', flex: '0.9' }}
            onClick={() => setShowSubscriberModal(true)}
            disabled={(selectedProbe?.devices[0]?.multiplexers[0] !== '' && selectedProbe?.devices[0]?.multiplexers[0] !== undefined)
              || (formValues?.multiplexer !== undefined && formValues?.multiplexer !== '') || selectedProbe?.devices[0]?.subscribers}
          >
            <ScaleIconActionAdd />
            Subscriber
          </ScaleButton>
        </div>
      </Form>
      <div style={{ display: 'flex', margin: '10px 0' }}>
        <ScaleButton
          slot="action"
          size="small"
          variant="secondary"
          className="text-button-link ml-auto pr-2 pl-2"
          type="button"
          onClick={() => { reset(); setCheckedSim(selectedSim); }}
          disabled={!touched && (checkedSim === selectedSim)}
        >
          Discard
        </ScaleButton>
        <ScaleButton
          slot="action"
          size="small"
          variant="primary"
          className="text-button-link pr-2 pl-2"
          onClick={() => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
          disabled={checkedSim === selectedSim && (formValues?.multiplexer === undefined || formValues?.multiplexer === '')}
        >
          Save
        </ScaleButton>
      </div>
      <ScaleDivider />
      {showSubscriberModal
          && (
            <AddSubscriberModal
              onHide={() => setShowSubscriberModal(false)}
              setErrMessage={setErrMessage}
              setMessage={setMessage}
              sims={sims}
              selectedSim={selectedSim}
              setSelectedSim={setSelectedSim}
              checkedSim={checkedSim}
              setCheckedSim={setCheckedSim}
              deviceData={selectedProbe?.devices[0]}
              getProbes={getProbes}
            />
          )}
    </>
  );
};

export default EditSubscriber;
