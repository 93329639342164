import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type AssignDeviceRequestData = {
  id: number | null
  deviceId: number
};

export default function useAssignDevice(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ id, deviceId }: AssignDeviceRequestData) => axios.put(
    `/sim-manager/api/subscribers/${id}/assign/${deviceId}`,
  ).then(({ data }) => data), options);
}
