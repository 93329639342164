import React, { useEffect, useState, useRef } from 'react';
import {
  Alert, Form,
} from 'react-bootstrap';
import { ScaleButton, ScaleModal, ScaleTextField } from '@telekom/scale-components-react';
import useForm from '../../../hooks/useForm';
import useRenameProbeLocation from '../../../hooks/services/probe/location/useRenameProbeLocation';

interface IModalProps {
  onHide: () => void;
  setErrMessage: (message: string) => void;
  setMessage: (message: string) => void;
  getLocations: any;
  country_iso: string;
  name: string;
}

type FormErrors = {
  country_name?: string;
};

const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

const RenameLocationModal = ({
  onHide, setErrMessage, setMessage, getLocations, country_iso, name,
}: IModalProps) => {
  const [showError, setShowError] = useState(true);
  const [opened, setOpened] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setOpened(true);
  }, []);

  const [renameLocation, { error }] = useRenameProbeLocation(
    {
      onSuccess: () => {
        setMessage('The location was successfully renamed');
        getLocations();
        onHide();
      },
      onError: () => setErrMessage('Unable to rename location'),
    },
  );

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues,
  } = useForm({
    initialValues: {
      country_name: name,
    },
    onSubmit: () => {
      renameLocation({
        country_iso,
        country_name: formValues?.country_name,
      });
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if (!values?.country_name || values?.country_name === '') {
        errors.country_name = 'Please fill in location name.';
      }
      if (values?.country_name && specialCharRegex.test(values?.country_name)) {
        errors.country_name = 'Special characters are not allowed';
      }
      return errors;
    },
  });

  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  return (
    <ScaleModal
      heading="Rename location"
      opened={opened}
      size="small"
      onScale-close={onHide}
      onScale-before-close={() => setOpened(false)}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        {error?.message && showError && (
        <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
          {error?.message}
        </Alert>
        )}
        <div>
          <ScaleTextField
            id="country_name"
            label="Name *"
            invalid={submitted && Boolean(formErrors?.country_name)}
            helperText="Special characters are not allowed"
            name="country_name"
            onChange={handleChange('country_name')}
            onScale-change={handleChange('country_name')}
            required
            type="text"
            value={formValues?.country_name}
            style={{ margin: '0 3px' }}
          />
        </div>
      </Form>
      <ScaleButton
        slot="action"
        variant="secondary"
        onClick={
          (event) => {
            event.preventDefault();
            onHide();
          }
        }
      >Cancel
      </ScaleButton>
      <ScaleButton
        slot="action"
        onClick={() => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
        disabled={formValues?.country_name === name}
      >Rename
      </ScaleButton>
    </ScaleModal>
  );
};

export default RenameLocationModal;
